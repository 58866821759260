import jsqr from "jsqr";

export interface QRResult {
  payload: string;
}

export class QRCodeReader {
  private element: HTMLElement;
  private video: HTMLVideoElement;
  private canvas: HTMLCanvasElement;
  private stream: MediaStream;
  private running: boolean = false;
  private resolve: (result: QRResult) => void;
  private reject: (error: any) => void;

  constructor() {
    const div = document.createElement("div");
    div.classList.add('qr-reading');
    const title = document.createElement("h1");
    title.textContent = "QRコードの読み取り";
    const header = document.createElement("header");
    header.classList.add('qr-preview-header');
    const video = document.createElement("video");
    video.setAttribute("autoplay", "");
    video.setAttribute("playsinline", "");
    this.canvas = document.createElement("canvas");
    this.element = div;
    this.video = video;
    const cancelButton = document.createElement("button");
    cancelButton.textContent = "中止";
    cancelButton.addEventListener("click", () => {
      this.stopScanning();
    });
    const description_wrapper = document.createElement("div");
    description_wrapper.classList.add('qr-description');
    const description = document.createElement("p");
    description.textContent = "QRコードをカメラに向けてください。";
    header.appendChild(title);
    header.appendChild(cancelButton);
    div.appendChild(header);
    div.appendChild(video);
    description_wrapper.appendChild(description);
    div.appendChild(description_wrapper);
  }

  public stopScanning() {
    const stream = this.stream;
    if (stream) {
      document.body.removeChild(this.element);
      this.video.srcObject = null;
      stream.getTracks().forEach(track => track.stop());
      this.stream = null;
      this.running = false;
      if (this.reject) {
        this.reject({
          error: "canceled"
        });
      }
    }
  }

  public startScanning(): Promise<QRResult> {
    return new Promise(async (resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
      if (!navigator.mediaDevices) {
        alert("この端末ではQRコードを読み取ることができません");
        return;
      } else {
        const devices = await navigator.mediaDevices.enumerateDevices();
        if (!devices.some((dev) => dev.kind === 'videoinput')) {
          alert('No video input device found');
          return;
        }
      }
      if (!navigator.mediaDevices.getUserMedia) {
        alert('No getUserMedia() function found');
        return;
      }
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            facingMode: {
              exact: 'environment'
            },
          },
        });
      } catch (e) {
        try {
          this.stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: true
          });
        } catch (e) {
          console.log(e);
          //alert('failed to get video input, ' + e);
          alert("カメラ操作を使用することが許可されませんでした。QRコードリーダーを起動するためにはカメラの使用を許可してください。");
          this.running = false;
          return;
        }
      }
      document.body.appendChild(this.element);
      this.video.srcObject = this.stream;
      this.video.play();
      this.running = true;
      requestAnimationFrame(() => {
        this.tick();
      }); // start scanning
    });
  }

  public readFromImage(): Promise<QRResult> {
    return new Promise((resolve, reject) => {
      const input = document.createElement("input");
      input.type = "file";
      input.addEventListener("change", (e) => {
        const files = input.files;
        if (files.length === 0) {
          reject();
          return;
        }
        const file = files[0];
        const fileReader = new FileReader();
        fileReader.addEventListener("load", e => {
          const url = <string>fileReader.result;
          const image = new Image();
          image.addEventListener("load", e => {
            const code = this.readCode(image, image.width, image.height);
            if (code !== null) {
              resolve({
                payload: code.data
              });
            } else {
              reject();
            }
          });
          image.src = url;
        });
        fileReader.readAsDataURL(file);
      });
      document.body.appendChild(input);
      input.click();
    });
  }

  private readCode(target: HTMLVideoElement | HTMLImageElement, width: number, height: number): QRCode {
    const canvas = this.canvas;
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext("2d");
    context.drawImage(target, 0, 0, canvas.width, canvas.height);
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const code = jsqr(imageData.data, imageData.width, imageData.height, {
      inversionAttempts: "dontInvert",
    });
    return code;
  }

  private tick() {
    if (!this.running) {
      return;
    }
    const video = this.video;
    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      const width = video.videoWidth;
      const height = (video.videoHeight / video.videoWidth * width) | 0;
      const code = this.readCode(video, width, height);
      console.log('code', code, width, height);
      if (code) {
        this.resolve({
          payload: code.data
        });
        this.resolve = null;
        this.reject = null;
        this.stopScanning();
        return;
      }
    }
    setTimeout(() => {
      this.tick();
    }, 1000);
  }
}
