import { FieldElementDefinition, TemplateManager } from "./digitalForm";
import { FieldDomElement } from "./DigitalForm/FieldDomElement";

export const TemplateUtil = TemplateManager.Util;

const serializeName = (element: FieldDomElement): string => {
    const familyName = <HTMLInputElement>element.subInput("family_name");
    const firstName = <HTMLInputElement>element.subInput("first_name");
    return familyName.value + "　" + firstName.value;
}

const deserializeName = (element: FieldDomElement, value: string) => {
    const token = !value ? [] : value.split("　");
    (<HTMLInputElement>element.subInput("family_name")).value = token[0] || "";
    (<HTMLInputElement>element.subInput("first_name")).value = token[1] || "";
};

const templates: { [s: string]: FieldElementDefinition } = {
    name: {
        kind: "dom",
        domTemplate: "input_name",
        value: serializeName,
        setValue: deserializeName,
        validate: {
            family_name: TemplateUtil.createValidatorNotEmptyStartWithJapaneseCharacter("姓"),
            first_name: TemplateUtil.createValidatorNotEmptyStartWithJapaneseCharacter("名")
        }
    },
    name_kanji_alphabet: {
        kind: "dom",
        domTemplate: "input_name_kanji_alphabet",
        value: serializeName,
        setValue: deserializeName,
        validate: {
        }
    },
    name_kana: {
        kind: "dom",
        domTemplate: "input_name_kana",
        value: serializeName,
        setValue: deserializeName,
        validate: {
            family_name: TemplateUtil.createValidatorKatakana("セイ"),
            first_name: TemplateUtil.createValidatorKatakana("メイ")
        }
    },
    name_hiragana: {
        kind: "dom",
        domTemplate: "input_name_hiragana",
        value: serializeName,
        setValue: deserializeName,
        validate: {
            family_name: TemplateUtil.createValidatorHiragana("姓"),
            first_name: TemplateUtil.createValidatorHiragana("名")
        }
    },
    email: {
        kind: "dom",
        domTemplate: "input_email",
        value: function (element: FieldDomElement) {
            return element.subInput("user").value + "@" + element.subInput("domain").value;
        },
        setValue: (element: FieldDomElement, value: string) => {
            const token = !value ? [] : value.split("@");
            (<HTMLInputElement>element.subInput("user")).value = token[0] || "";
            (<HTMLInputElement>element.subInput("domain")).value = token[1] || "";
        },
        validate: {
            "user": TemplateUtil.createValidatorEMail(),
            "domain": TemplateUtil.createValidatorEMailDomain()
        }
    },
    age: {
        kind: "dom",
        domTemplate: "input_age",
        value: function (element: FieldDomElement) {
            return element.subInput("age").value;
        },
        validate: {
            age: TemplateUtil.createValidatorDigit()
        }
    },
    zipcode: {
        kind: "zipcode",
        id: "zipcode"
    },
    gender: {
        kind: "radio",
        id: "gender",
        options: {
            "男": "男",
            "女": "女"
        }
    },
    gender_optional: {
        kind: "radio",
        id: "gender_optional",
        options: {
            "男": "男",
            "女": "女",
            "回答しない": "回答しない"
        }
    },
    word: {
        kind: "dom",
        domTemplate: "input_word",
    },
    address: {
        kind: "dom",
        domTemplate: "input_address",
        validate: {
            address: TemplateUtil.createValidatorStartWithJapaneseCharacter()
        }
    },
    address_kana: {
        kind: "dom",
        domTemplate: "input_address_kana",
        validate: (element: FieldDomElement) => {
            const errors: string[] = [];
            if (!TemplateUtil.isKatakanaString(element.value)) {
                errors.push("全角カタカナで入力してください");
            }
            return errors;
        }
    },
    building_info: {
        kind: "dom",
        domTemplate: "input_building_info",
        validate: {
            building_info: TemplateUtil.createValidatorStartWithJapaneseCharacter()
        }
    },
    phone_number: {
        kind: "dom",
        domTemplate: "input_phone_number",
        value: (element: FieldDomElement) => {
            const value1 = element.subInput("(XXX)___-____").value;
            const value2 = element.subInput("(___)XXX-____").value;
            const value3 = element.subInput("(___)___-XXXX").value;
            if (value1 || value2 || value3) {
                const list = [];
                if (value1) list.push(`(${value1})`);
                if (value2) list.push(value2);
                if (value3) list.push(value3);
                return list.join("-");
            }
            return "";
        },
        setValue: (element: FieldDomElement, value: string) => {
            const result = /^\((.*)\)-(.*)-(.*)$/.exec(value);
            if (result) {
                element.subInput("(XXX)___-____").value = result[1];
                element.subInput("(___)XXX-____").value = result[2];
                element.subInput("(___)___-XXXX").value = result[3];
            }
        },
        validate: {
            "(XXX)___-____": TemplateUtil.createValidatorDigit(),
            "(___)XXX-____": TemplateUtil.createValidatorDigit(),
            "(___)___-XXXX": TemplateUtil.createValidatorDigit()
        }
    },
    local_phone_number: {
        kind: "dom",
        domTemplate: "input_local_phone_number",
        value: (element: FieldDomElement) => {
            const value1 = element.subInput("XXX-____").value;
            const value2 = element.subInput("___-XXXX").value;
            if (value1 || value2) {
                return `${value1}-${value2}`;
            }
            return "";
        },
        validate: {
            "XXX-____": TemplateUtil.createValidatorDigit(),
            "___-XXXX": TemplateUtil.createValidatorDigit()
        }
    },
    birthdate: {
        kind: "dom",
        domTemplate: "input_birthdate",
        value: (element: FieldDomElement) => {
            return {
                year: TemplateUtil.convertToASCIIDigit(element.subInput("year").value),
                month: TemplateUtil.convertToASCIIDigit(element.subInput("month").value),
                date: TemplateUtil.convertToASCIIDigit(element.subInput("date").value)
            };
        },
        validate: {
            year: function (value) {
                const errors = [];
                const year = JSON.parse(this.value).year;
                if (!isFinite(year) || year < 2000) errors.push("正しい年を入力してください");
                return errors;
            },
            month: function (value) {
                const errors = [];
                const month = JSON.parse(this.value).month;
                if (!isFinite(month) || month <= 0 || month > 12) errors.push("正しい月を入力してください");
                return errors;
            },
            date: function (value) {
                const errors = [];
                const date = JSON.parse(this.value).date;
                if (!isFinite(date) || date <= 0 || date > 31) errors.push("正しい日を入力してください");
                return errors;
            }
        }
    },
    relation: {
        kind: "dom",
        domTemplate: "input_relation",
        validate: {
            relation: TemplateUtil.createValidatorStartWithJapaneseCharacter()
        }
    },
    map: {
        kind: "dom",
        domTemplate: "input_map",
        manualUpdate: (element: FieldDomElement) => {
            element.subInput("select_button").addEventListener("click", () => {
                element.update();
            });
        },
        setup: (element: FieldDomElement) => {
            const center = { lat: 34.694782, lng: 135.195507 };
            if (!window.google) {
                console.error("failed to load google map module");
                return;
            }
            const map = new google.maps.Map(element.subInput("map"), {
                center: center,
                zoom: 15,
                clickableIcons: false,
                mapTypeControl: false,
                streetViewControl: false
            });
            map.addListener("click", (e) => {
                element.marker.setPosition(e.latLng);
            });
            map.setOptions({
                styles: [
                    { featureType: 'poi', stylers: [{ visibility: 'on' }] },
                    { featureType: 'transit', stylers: [{ visibility: 'off' }] },
                    { featureType: 'administrative', stylers: [{ visibility: 'on' }] },
                    { featureType: 'road', stylers: [{ visibility: 'simplified' }] }
                ]
            });
            element.mapObject = map;
            element.marker = new google.maps.Marker({
                position: center,
                map: map
            });
        },
        value: (element: FieldDomElement) => {
            const center = { lat: 34.694782, lng: 135.195507 };
            let data = {
                center,
                zoom: 15,
                marker: center
            };
            if (window.google) {
                data = {
                    center: element.mapObject.getCenter(),
                    zoom: element.mapObject.getZoom(),
                    marker: element.marker.position
                };
            }
            return data;
        }
    }
};

TemplateManager.addTemplates(templates);

function option(label: string, value: string): HTMLOptionElement {
    const option = document.createElement("option");
    option.value = value;
    option.textContent = label;
    return option;
}

function updateYears(element: FieldDomElement, eras) {
    const gengo = <HTMLSelectElement>element.subInput("gengo");
    const year = <HTMLSelectElement>element.subInput("year");
    const eraIndex = gengo.selectedIndex;
    year.innerHTML = "";
    year.appendChild(option("--", ""));
    if (eraIndex < 1) return;
    const max = eras[eraIndex - 1].max;
    let value = parseInt(year.value);
    for (let j = 0; j < max; j++) {
        const o = option(
            j == 0 ? "元" : String(j + 1), String(j + 1)
        );
        o.selected = j === 0;
        year.appendChild(o);
    }
    if (isNaN(value)) {
        year.selectedIndex = 0;
    } else {
        year.value = String(value > max ? 1 : value);
    }
}

const selectLabel = (select: HTMLSelectElement, label: string) => {
    for (let i = 0, l = select.options.length; i < l; i++) {
        const option = select.options[i];
        if (option.textContent === label) {
            select.selectedIndex = i;
            dispatchOnChange(select);
            break;
        }
    }
};

const updateGengoYear = (gengoYear, gengo, year) => {
    gengoYear.value = gengo.value + year.value;
    const e = document.createEvent("HTMLEvents");
    e.initEvent("change", false, true);
    gengoYear.dispatchEvent(e);
};

const dispatchOnChange = (element: HTMLElement) => {
    const e = document.createEvent("HTMLEvents");
    e.initEvent("change", false, true);
    element.dispatchEvent(e);
};

const getSelectedLabel = (select: HTMLSelectElement) => {
    if (select.selectedIndex >= 0) {
        return select.options[select.selectedIndex].textContent;
    }
    return "";
};

TemplateManager.addTemplate("date_in_japanese_calendar", {
    kind: "dom",
    domTemplate: "input_date_in_japanese_calendar",
    value: (element: FieldDomElement) => {
        return {
            gengo: getSelectedLabel(<HTMLSelectElement>element.subInput("gengo")),
            gengo_year: element.subInput("gengo_year").value,
            year: getSelectedLabel(<HTMLSelectElement>element.subInput("year")),
            month: getSelectedLabel(<HTMLSelectElement>element.subInput("month")),
            date: getSelectedLabel(<HTMLSelectElement>element.subInput("date"))
        };
    },
    setValue: (element: FieldDomElement, value: any) => {
        const gengo = <HTMLSelectElement>element.subInput("gengo");
        const year = <HTMLSelectElement>element.subInput("year");
        const month = <HTMLSelectElement>element.subInput("month");
        const date = <HTMLSelectElement>element.subInput("date");
        if (value) {
            selectLabel(gengo, value.gengo);
            updateGengoYear(element.subInput("gengo_year"), gengo, year);
            selectLabel(year, value.year);
            selectLabel(month, value.month);
            selectLabel(date, value.date);
        }
    },
    setup: (element: FieldDomElement, definition) => {
        const gengo = <HTMLSelectElement>element.subInput("gengo");
        const year = <HTMLSelectElement>element.subInput("year");
        const month = <HTMLSelectElement>element.subInput("month");
        const date = <HTMLSelectElement>element.subInput("date");
        const gengoYear = <HTMLInputElement>element.subInput("gengo_year");
        const eras = definition.context.eras;
        gengo.appendChild(option("--", ""));
        for (let i = 0, l = eras.length; i < l; i++) {
            gengo.appendChild(option(eras[i].name, eras[i].value));
        }
        updateGengoYear(gengoYear, gengo, year);
        gengo.addEventListener("change", () => {
            updateYears(element, eras);
            updateGengoYear(gengoYear, gengo, year);
        });
        year.addEventListener("change", () => {
            updateGengoYear(gengoYear, gengo, year);
        });
        month.appendChild(option("--", ""));
        for (let i = 0; i < 12; i++) {
            month.appendChild(option(String(i + 1), String(i + 1)));
        }
        date.appendChild(option("--", ""))
        for (let i = 0; i < 31; i++) {
            date.appendChild(option(String(i + 1), String(i + 1)));
        }
        gengo.value = "";//definition.context.initialValue.gengo;
        year.value = "";//definition.context.initialValue.year;
        month.value = "";//definition.context.initialValue.month;
        date.value = "";//definition.context.initialValue.date;
        updateYears(element, eras);
        updateGengoYear(gengoYear, gengo, year);
    },
    validate: {
        gengo: function (value) {
            return value === "" ? ["元号を選択してください"] : [];
        },
        year: function (value) {
            return value === "" ? ["年を選択してください"] : [];
        },
        month: function (value) {
            return value === "" ? ["月を選択してください"] : [];
        },
        date: function (value) {
            return value === "" ? ["日を選択してください"] : [];
        }
    }
});

TemplateManager.addTemplate("year_month_in_japanese_calendar", {
    kind: "dom",
    domTemplate: "input_year_month_in_japanese_calendar",
    value: (element: FieldDomElement) => {
        return {
            gengo: getSelectedLabel(<HTMLSelectElement>element.subInput("gengo")),
            gengo_year: element.subInput("gengo_year").value,
            year: getSelectedLabel(<HTMLSelectElement>element.subInput("year")),
            month: getSelectedLabel(<HTMLSelectElement>element.subInput("month")),
        };
    },
    setValue: (element: FieldDomElement, value: any) => {
        const gengo = <HTMLSelectElement>element.subInput("gengo");
        const year = <HTMLSelectElement>element.subInput("year");
        const month = <HTMLSelectElement>element.subInput("month");
        if (value) {
            selectLabel(gengo, value.gengo);
            updateGengoYear(element.subInput("gengo_year"), gengo, year);
            selectLabel(year, value.year);
            selectLabel(month, value.month);
        }
    },
    setup: (element: FieldDomElement, definition) => {
        const gengo = <HTMLSelectElement>element.subInput("gengo");
        const year = <HTMLSelectElement>element.subInput("year");
        const month = <HTMLSelectElement>element.subInput("month");
        const gengoYear = <HTMLInputElement>element.subInput("gengo_year");
        const eras = definition.context.eras;
        gengo.appendChild(option("--", ""));
        for (let i = 0, l = eras.length; i < l; i++) {
            gengo.appendChild(option(eras[i].name, eras[i].value));
        }
        updateGengoYear(gengoYear, gengo, year);
        gengo.addEventListener("change", () => {
            updateYears(element, eras);
            updateGengoYear(gengoYear, gengo, year);
        });
        year.addEventListener("change", () => {
            updateGengoYear(gengoYear, gengo, year);
        });
        month.appendChild(option("--", ""));
        for (let i = 0; i < 12; i++) {
            month.appendChild(option(String(i + 1), String(i + 1)));
        }
        gengo.value = "";//definition.context.initialValue.gengo;
        year.value = "";//definition.context.initialValue.year;
        month.value = "";//definition.context.initialValue.month;
        updateYears(element, eras);
        updateGengoYear(gengoYear, gengo, year);
    },
    validate: {
        gengo: function (value) {
            return value === "" ? ["元号を選択してください"] : [];
        },
        year: function (value) {
            return value === "" ? ["年を選択してください"] : [];
        },
        month: function (value) {
            return value === "" ? ["月を選択してください"] : [];
        }
    }
});

TemplateManager.addTemplate("pregnancy_week", {
    kind: "dom",
    domTemplate: "input_pregnancy_week",
    value: (element: FieldDomElement) => {
        return {
            weeks: (<HTMLInputElement>element.subInput("weeks")).value,
            days: (<HTMLInputElement>element.subInput("days")).value,
        };
    },
    setValue: (element: FieldDomElement, value: any) => {
        const weeks = <HTMLSelectElement>element.subInput("weeks");
        const days = <HTMLSelectElement>element.subInput("days");
        if (value) {
            weeks.value = value.weeks;
            days.value = value.days;
        }
    },
    setup: (element: FieldDomElement, definition) => {
        const weeks = <HTMLSelectElement>element.subInput("weeks");
        const days = <HTMLSelectElement>element.subInput("days");
        weeks.value = "";
        days.value = "";
    },
    validate: {
        weeks: TemplateUtil.createValidatorDigit(),
        days: TemplateUtil.createValidatorDigit(),
    }
});

TemplateManager.addTemplate("time", {
    kind: "dom",
    domTemplate: "input_time",
    value: (element: FieldDomElement) => {
        return {
            ampm: getSelectedLabel(<HTMLSelectElement>element.subInput("ampm")),
            hour: getSelectedLabel(<HTMLSelectElement>element.subInput("hour")),
            minute: getSelectedLabel(<HTMLSelectElement>element.subInput("minute")),
        };
    },
    setValue: (element: FieldDomElement, value: any) => {
        const ampm = <HTMLSelectElement>element.subInput("ampm");
        const hour = <HTMLSelectElement>element.subInput("hour");
        const minute = <HTMLSelectElement>element.subInput("minute");
        if (value) {
            selectLabel(ampm, value.ampm);
            selectLabel(hour, value.hour);
            selectLabel(minute, value.minute);
        }
    },
    setup: (element: FieldDomElement, definition) => {
        const hour = <HTMLSelectElement>element.subInput("hour");
        const minute = <HTMLSelectElement>element.subInput("minute");
        hour.appendChild(option("--", ""));
        for (let i = 0, l = 12; i < l; i++) {
            hour.appendChild(option(String(i), String(i)));
        }
        minute.appendChild(option("--", ""));
        for (let i = 0, l = 60; i < l; i++) {
            minute.appendChild(option(String(i), String(i)));
        }
        hour.value = "";//definition.context.initialValue.gengo;
        minute.value = "";//definition.context.initialValue.year;
    },
    validate: {
        ampm: function (value) {
            return value === "" ? ["午前午後を選択してください"] : [];
        },
        hour: function (value) {
            return value === "" ? ["時間を選択してください"] : [];
        },
        minute: function (value) {
            return value === "" ? ["分を選択してください"] : [];
        },
    }
});