import { TemplateManager } from '../../../src/digitalForm';

TemplateManager.addTemplates({
  former_education_info: {
      kind: "field",
      fields: [
          {
              kind: "element",
              id: "type",
              title: "就学前に通っていたところ",
              type: "radio",
              options: {
                  "保育所": "保育所",
                  "保育園": "保育園",
                  "幼稚園": "幼稚園",
                  "その他": "その他"
              }
          },
          {
              kind: "element",
              id: "name",
              title: "就学前に通っていた施設の名称",
              description: "就学前に入所していた保育園・幼稚園等の名前を入力してください。",
              type: "text",
              suffix: "保育園・幼稚園",
              placeholder: "例：〇〇保育園"
          },
          {
              kind: "element",
              id: "years",
              title: "就学前に通っていた施設での保育年数",
              description: "1年に満たない端数は切り捨てて入力してください。(例：2年4か月在園した場合は「2」と入力)",
              type: "number",
              suffix: "年",
              min: 1,
              max: 5
          },
          {
              kind: "template",
              id: "phone_number",
              title: "入所していた保育園・幼稚園等の電話番号",
              template: "phone_number",
          }
      ]
  },
  beneficiary_info: {
      kind: "field",
      fields: [
          {
              kind: "element",
              id: "relation",
              title: "世帯主との続柄",
              type: "radio",
              options: {
                  self: "本人",
                  wife: "妻",
                  husband: "夫",
                  child: "子",
                  father: "父",
                  mother: "母",
                  other: "その他"
              },
              required: true
          },
          {
              kind: "template",
              id: "other",
              title: "その他の続柄",
              description: "「妻の母」「弟」「妻（未届）」「夫の子」など、世帯主から見た続柄を入力してください",
              template: "relation",
              condition: "relation",
              required: true
          },
          {
            kind: "template",
            id: "name",
            template: "name_kanji_alphabet",
            title: "名前（漢字・アルファベット）",
            required: true
        },
    {
              kind: "template",
              title: "名前（フリガナ）",
              template: "name_kana",
              required: true
          },
          {
            kind: "template",
            id: "birthdate",
            title: "生年月日",
            template: "date_in_japanese_calendar",
            required: true,
            context: {
                eras: [
                    {
                        name: "昭和",
                        value: "showa",
                        max: 64
                    },
                    {
                        name: "平成",
                        value: "heisei",
                        max: 31
                    },
                    {
                        name: "令和",
                        value: "reiwa",
                        max: 1
                    }
                ],
                initialValue: {
                    gengo: "heisei",
                    year: "1",
                    month: "1",
                    date: "1"
                }
            }
        },
    {
              kind: "template",
              title: "性別",
              template: "gender"
          },
      ]
  },
  employee_name: {
      kind: "dom",
      domTemplate: "input_employee_name"
  },
  occupation: {
      kind: "dom",
      domTemplate: "input_occupation",
      validate: {
          occupation: TemplateManager.Util.createValidatorStartWithJapaneseCharacter()
      }
  },
  health_condition: {
      kind: "dom",
      domTemplate: "input_health_condition",
      validate: {
          health_condition: TemplateManager.Util.createValidatorStartWithJapaneseCharacter()
      }
  },
  grand_parent_info: {
      kind: "field",
      fields: [
          {
              kind: "template",
              title: "名前（漢字）：",
              template: "name",
              extraSetup: (input) => {
                  input.map["family_name"].setAttribute('placeholder', '例：神戸');
                  input.map["first_name"].setAttribute('placeholder', '例：太郎');
              },
              required: true
          },
          {
              kind: "template",
              title: "年齢",
              template: "age",
              description: "放課後児童クラブ利用開始時点での年齢を入力してください。",
              extraSetup: (input) => {
                  input.map["age"].setAttribute('placeholder','例：60');
              },
              required: true
          },
          {
              kind: "template",
              title: "職業",
              template: "occupation"
          },
          {
              kind: "template",
              title: "健康状態",
              template: "health_condition",
              required: true
          },
          {
              kind: "template",
              title: "住所",
              description: "放課後児童クラブ利用開始時点での住所を市区町村名から入力してください。",
              template: "address",
              extraSetup: (input) => {
                  input.map["address"].setAttribute('placeholder','例：神戸市◯◯区◯◯町〇〇丁目〇-〇');
              },
              required: true
          },
          {
              kind: "template",
              title: "電話番号",
              template: "phone_number",
              extraSetup: (input) => {
                  input.map["(XXX)___-____"].setAttribute('placeholder', '例：090');
                  input.map["(___)XXX-____"].setAttribute('placeholder', '例：0000');
                  input.map["(___)___-XXXX"].setAttribute('placeholder', '例：0000');
              }
          }
      ]
  },
  emergency_contact_info: {
      kind: "field",
      fields: [
          {
              kind: "template",
              title: "名前（漢字）：",
              template: "name",
              extraSetup: (input) => {
                  input.map["family_name"].setAttribute('placeholder', '例：神戸');
                  input.map["first_name"].setAttribute('placeholder', '例：花子');
              },
              required: true
          },
          {
              kind: "template",
              title: "続柄",
              template: "relation",
              extraSetup: (input) => {
                  input.map["relation"].setAttribute('placeholder', '例：母');
              },
              required: true
          },
          {
              kind: "template",
              title: "電話番号",
              template: "phone_number",
              extraSetup: (input) => {
                  input.map["(XXX)___-____"].setAttribute('placeholder', '例：090');
                  input.map["(___)XXX-____"].setAttribute('placeholder', '例：0000');
                  input.map["(___)___-XXXX"].setAttribute('placeholder', '例：0000');
              },
              required: true
          },
      ]
  },
  non_japan_post_account_information: {
      kind: "field",
      fields: [
          {
              kind: "element",
              id: "bank_type",
              type: "radio",
              title: "金融機関種別",
              options: {
                  "銀行": "銀行",
                  "信用金庫": "信用金庫",
                  "信用組合": "信用組合",
                  "農協": "農協",
                  "": "その他"
              },
              required: true
          },
          {
              kind: "element",
              id: "bank_name",
              title: "金融機関名称",
              type: "text",
              suffix: "銀行・信用金庫・信用組合・農協",
              placeholder: "例：〇〇銀行",
              required: true
          },
          {
              kind: "element",
              id: "branch_name",
              title: "店名",
              description: "○○支店、△△出張所等、通帳に記載の店名を入力してください。",
              type: "text",
              placeholder: "例：〇〇支店",
              required: true
          },
          {
              kind: "element",
              id: "bank_account_type",
              title: "預金種別",
              type: "radio",
              options: {
                  "普通総合": "普通（総合）",
                  "当座": "当座"
              },
              required: true
          },
          {
              kind: "element",
              id: "bank_account_number",
              title: "口座番号",
              type: "digit",
              min: 4,
              max: 7,
              placeholder: "例：0000000",
              required: true
          },
          {
              kind: "field",
              id: "bank_account_owner",
              title: "口座名義人",
              fields: [
                  {
                      kind: "template",
                      title: "名前（漢字）：",
                      template: "name",
                      required: true
                  },
                  {
                      kind: "template",
                      title: "名前（フリガナ）：",
                      template: "name_kana",
                      required: true
                  }
              ]
          }
      ]
  },
  japan_post_account_information: {
      kind: "field",
      fields: [
          {
              kind: "element",
              id: "bank_account_symbol",
              title: "通帳記号",
              description: "通帳に記載されている記号（1からはじまり0で終わる5桁の数字）を入力してください。",
              type: "digit",
              min: 5,
              max: 5,
              placeholder: "例：10000",
              required: true
          },
          {
              kind: "element",
              id: "bank_account_number",
              title: "通帳番号",
              description: "通帳に記載されている番号(最大8桁)を入力してください。",
              type: "digit",
              max: 8,
              placeholder: "例：00000000",
              required: true
          },
          {
              kind: "field",
              id: "bank_account_owner",
              fields: [
                  {
                      kind: "template",
                      title: "口座名義人の住所",
                      template: "address",
                      extraSetup: (input) => {
                          input.map["address"].setAttribute('placeholder', '例：神戸市中央区加納町6-5-1');
                      },
                      required: true
                  },
                  {
                      kind: "template",
                      title: "口座名義人の住所（フリガナ）",
                      template: "address_kana",
                      required: true
                  },
                  {
                      kind: "template",
                      title: "口座名義人の名前（漢字）：",
                      template: "name",
                      required: true
                  },
                  {
                      kind: "template",
                      title: "口座名義人の名前（フリガナ）：",
                      template: "name_kana",
                      required: true
                  },
              ]
          },
      ]
  },
  notifier_info: {
      kind: "field",
      fields: [
        {
            kind: "template",
            id: "name",
            template: "name_kanji_alphabet",
            title: "名前（漢字・アルファベット）",
            required: true
        },
        {
            kind: "template",
            title: "郵便番号",
            template: "zipcode",
            required: true
        },
{
              kind: "template",
              title: "住所",
              template: "address",
              required: true
          }
      ]
  }
});