import {
    DigitalApplicationDefinition, DigitalFormDefinition, sync, conditionalSync, FormElementPath
} from '../../../src/digitalForm';

import { setupZipcodeLookup } from "../../../src/FormDataUtil";
import { } from "../src/templates";

import birth_report from "./birth_report.json";
import child_medical_expense from './child_medical_expense.json';
import childcare_allowance_certtificate from './childcare_allowance_certificate.json';
import childcare_allowance_revision from './childcare_allowance_revision.json';
import national_pension from './national_pension.json';
import national_health_insurance from "./national_health_insurance.json";
import { DigitalForm } from '../../../src/DigitalForm/DigitalForm';
import { findFieldInput } from '../../../src/DigitalForm/Element/FieldInputContainer';
import { FieldInputElement } from '../../../src/DigitalForm/Element/FieldInputElement';
import { Notifier } from '../../../src/main';

export const FormDefinition: DigitalApplicationDefinition = {
    forms: [
        <DigitalFormDefinition>birth_report,
        <DigitalFormDefinition>national_health_insurance,
        <DigitalFormDefinition>national_pension,
        <DigitalFormDefinition>childcare_allowance_certtificate,
        <DigitalFormDefinition>childcare_allowance_revision,
        <DigitalFormDefinition>child_medical_expense,
    ],
    extraSetup: (formMap: { [keys: string]: DigitalForm }, definitionMap: { [keys: string]: DigitalFormDefinition }) => {
        const { birth_report,
            child_medical_expense,
            childcare_allowance_certificate,
            childcare_allowance_revision,
            national_pension,
            national_health_insurance } = formMap;

        //届出日
        sync([birth_report, "input_date"],
            [national_health_insurance, "notification_date"],
            [national_pension, "notification_date"],
            [childcare_allowance_certificate, "procedure_date"],
            [childcare_allowance_revision, "input_date"],
            [child_medical_expense, "procedure_date"]);

        //お子さんの名前 漢字
        sync([birth_report, "child_name_kanji"],
            [national_health_insurance, "child_name"],
            [childcare_allowance_certificate, "child_name"],
            [childcare_allowance_revision, "child_name"],
            [child_medical_expense, "child_name"]);

        //お子さんの名前 カナ
        sync([birth_report, "child_name_kana"],
            [national_health_insurance, "child_kana"],
            [childcare_allowance_certificate, "child_kana"],
            [childcare_allowance_revision, "child_name_kana"],
            [child_medical_expense, "child_name_kana"]);

        //お子さんの生年月日
        sync([birth_report, "birthdate"],
            [national_health_insurance, "child_birthdate"],
            [national_pension, "birth_date"],
            [childcare_allowance_certificate, "child_birthdate"],
            [childcare_allowance_revision, "child_birthdate"],
            [child_medical_expense, "child_birthdate"]);

        //お子さんの性別
        sync([birth_report, "gender"],
            [national_health_insurance, "gender"],
            [child_medical_expense, "gender"]);

        //お母さんの名前
        sync([birth_report, "mother_name"],
            [national_pension, "name"]);

        //お母さんの名前　カナ
        sync([birth_report, "mother_kana"],
            [national_pension, "name_kana"]);

        //お母さんの生年月日
        sync([birth_report, "mother_birthdate"],
            [national_pension, "birthdate"]);

        //お母さんの郵便番号
        sync([birth_report, "zipcode"],
            [national_pension, "zipcode"]);

        //お母さんの住所
        sync([birth_report, "address"],
            [national_pension, "address"]);

        //単・多胎の別
        sync([birth_report, "birth_type"],
            [national_pension, "number_of_fetus"]);

        //世帯主の名前 漢字
        sync(
            // [national_health_insurance, "household_name"],
            [childcare_allowance_certificate, "applicant_name"],
            [childcare_allowance_revision, "name"],
            [child_medical_expense, "myname"]);

        //世帯主の名前 カナ
        sync([childcare_allowance_certificate, "applicant_kana"],
            [childcare_allowance_revision, "name_kana"],
            [child_medical_expense, "myname_kana"]);

        //勤め先を連動
        sync([childcare_allowance_certificate, "public_worker"],
            [childcare_allowance_revision, "komuin"]);

        //世帯主の郵便番号
        sync(
            // [national_health_insurance, "zipcode"],
            [childcare_allowance_certificate, "zipcode"],
            [childcare_allowance_revision, "zipcode"]);

        //世帯主の住所
        sync(
            // [national_health_insurance, "household_address"],
            [childcare_allowance_certificate, "address"],
            [childcare_allowance_revision, "address"]);

        //世帯主の電話番号
        sync(
            // [national_health_insurance, "phone_number"],
            [childcare_allowance_certificate, "phone_number"],
            [childcare_allowance_revision, "landline"],
            [child_medical_expense, "phone_number"]);

        //世帯主のマイナンバー
        sync([childcare_allowance_certificate, "mynumber"],
            [child_medical_expense, "mynumber"]);

        //同居
        sync(
            [childcare_allowance_certificate, "living"],
            [childcare_allowance_revision, "living"],
            [child_medical_expense, "living"]
        );

        //生計中心者の生年月日
        sync(
            [childcare_allowance_certificate, "birthdate"],
            [childcare_allowance_revision, "birthdate"],
            [child_medical_expense, "birthdate"]
        );

        const liveTogetherPath: FormElementPath = [childcare_allowance_certificate, "live_together"];
        conditionalSync(liveTogetherPath, "live_together",
            [childcare_allowance_certificate, "address"],
            [childcare_allowance_certificate, "partner_address"]);
        conditionalSync(liveTogetherPath, "live_together",
            [childcare_allowance_certificate, "zipcode"],
            [childcare_allowance_certificate, "partner_zipcode"]);

        const liveTogetherPath2: FormElementPath = [child_medical_expense, "living"];
        conditionalSync(liveTogetherPath2, "live_together",
            [childcare_allowance_certificate, "address"],
            [childcare_allowance_revision, "address"],
            [child_medical_expense, "child_address"]);
        conditionalSync(liveTogetherPath2, "live_together",
            [childcare_allowance_certificate, "zipcode"],
            [childcare_allowance_revision, "zipcode"],
            [child_medical_expense, "child_zipcode"]);

        Object.keys(definitionMap).forEach(id => {
            const form = formMap[id];
            definitionMap[id].boxes.forEach(boxDefinition => {
                if (boxDefinition.condition) {
                    const element = findFieldInput(form, boxDefinition.condition);
                    element.virtualInput.addEventListener("change", () => {
                        const box = findFieldInput(form, boxDefinition.id);
                        console.log(element.value, boxDefinition.id, box);
                        box.disabled = element.value !== boxDefinition.id;
                    });
                }
            });
        });
        if (childcare_allowance_certificate) {
            const box = findFieldInput(childcare_allowance_certificate, "spouse");
            box.disabled = true;
        }
    },
    onFinishPreview: () => {
        Notifier.confirm("入力は全てお済みですか?\nPDFまたはQRコードを保存の上、終了してください。", result => {
            if (result) {
                location.href = __RESET_URL__;
            }
        });
    }
};
