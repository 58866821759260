import { DOMTemplate } from "./denki";
import { FieldBox } from "./DigitalForm/FieldBox";
import { DigitalForm } from "./DigitalForm/DigitalForm";
import * as SDK from "../sdk/src/digitalForm";

export interface FormElementInterface {
    id?: string,
    title?: string,
    description?: string,
    required?: boolean
}

type FieldCondition = string | { id: string, value: string | string[] | number[] };

export interface ElementInterface {
    id?: string,
    title?: string,
    description?: string,
    required?: boolean,
    context?: any,
    hidden?: boolean,
    condition?: FieldCondition,
    refer?: string,
    cardinality?: Cardinality,
}

export interface PrefixSuffixInterface {
    prefix: string,
    suffix: string
}

export type Cardinality = {
    min?: number,
    max?: number,
} | number | string;

type Validator = "StartWithJapanese" | "NumberOnly" | "KatakanaOnly" | {
    kind: "SpecifiedLengthDigitsOnly",
    pattern: number[]
}

export interface TextElementDefinition extends ElementInterface {
    kind: "text",
    id: string,
    prefix?: string,
    suffix?: string,
    placeholder?: string,
    min?: number,
    max?: number,
    validator?: Validator,
    class?: string,
    readonly?: boolean;
}

export interface OptionTypeLabelValue {
    label: string,
    value: string
}

export type OptionType = string | OptionTypeLabelValue;

export interface RadioElementDefinition extends ElementInterface {
    kind: "radio",
    id: string,
    options: OptionType[]
}

export interface CheckBoxElementDefinition extends ElementInterface {
    kind: "checkbox",
    id: string,
    options: OptionType[]
}

export interface SelectElementDefinition extends ElementInterface {
    kind: "select",
    id: string,
    options: OptionType[],
    initialValue?: string
}

export interface NumberElementDefinition extends ElementInterface {
    kind: "number",
    id: string,
    prefix?: string,
    suffix?: string,
    min?: number,
    max?: number
    initialValue?: number
}

export interface TextAreaElementDefinition extends ElementInterface {
    kind: "textarea",
    id: string,
    placeholder?: string,
    maxLength?: number;
}

export interface ZipCodeElementDefinition extends ElementInterface {
    kind: "zipcode",
    id: string
}

export interface PhoneNumberElementDefinition extends ElementInterface  {
    kind: "phone_number",
    id: string
}

export type ElementDefinition = TextElementDefinition
    | RadioElementDefinition
    | CheckBoxElementDefinition
    | SelectElementDefinition
    | NumberElementDefinition
    | TextAreaElementDefinition
    | ZipCodeElementDefinition
    | PhoneNumberElementDefinition;

export interface TemplateReference extends ElementInterface {
    kind: "template",
    template: string,
    condition?: FieldCondition,
    cardinality?: Cardinality,
    extraSetup?: (FieldInputElement) => void
    hidden?: boolean
}

export interface FieldObjectDefinition extends ElementInterface {
    kind: "field",
    id?: string,
    fields: FieldElementDefinition[],
    value?: (element: FieldCompositeElement) => string,
    focus?: (element: FieldCompositeElement) => void,
    setup?: (element: FieldCompositeElement, definition?: FieldObjectDefinition) => void,
    condition?: FieldCondition,
    cardinality?: Cardinality,
    extraSetup?: (element: FieldCompositeElement, definition?: FieldObjectDefinition) => void
    hidden?: boolean
    index?: number
}

type ValidatorMap = { [key: string]: (value: string) => string[] }
export interface FieldDomTemplate extends ElementInterface {
    kind: "dom",
    domTemplate: string,
    value?: (element: FieldDomElement) => string,
    setValue?: (element: FieldDomElement, value: string) => void,
    focus?: () => void
    validate?: ((element: FieldDomElement) => string[]) | ValidatorMap,
    setup?: (element: FieldDomElement, definition?: FieldDomTemplate) => void,
    condition?: FieldCondition,
    cardinality?: Cardinality,
    manualUpdate?: (element: FieldDomElement) => void,
    extraSetup?: (element: FieldDomElement, definition?: FieldDomTemplate) => void
}

export type FieldElementConcreteDefinition = ElementDefinition
    | FieldObjectDefinition
    | FieldDomTemplate;

export type FieldElementDefinition = FieldElementConcreteDefinition | TemplateReference;

export interface DigitalFormBoxDefinition {
    id: string,
    title?: string,
    description?: string,
    required?: boolean,
    hidden?: boolean,
    condition?: string;
    elements: FieldElementDefinition[]
}

export interface FormRenderingSetting {
    path: string,
    numberOfPages: number;
    extraPages?: ExtraPageDefinition[]
}

export type Persona = { [keys: string]: any };
export type PersonaMap = { [keys: string]: Persona};

export interface DigitalFormDefinition {
    id: string
    title: string,
    rendering: FormRenderingSetting
    boxes: DigitalFormBoxDefinition[],
    persona?: PersonaMap,
    extraSetup?: (form: DigitalForm) => void,
    qr_template?: string
}

export interface ExtraPageDefinition {
    kind: "html" | "image";
    url: string;
}

export interface DigitalApplicationDefinition {
    pdfFileName?: string,
    pdfMetaTitle?: string
    forms: DigitalFormDefinition[],
    extraPages?: ExtraPageDefinition[],
    extraSetup?: (formMap: { [keys: string]: DigitalForm }, definitionMap: { [keys: string]: DigitalFormDefinition }) => void,
    onFinishPreview?: () => void;
    roleMap?: SDK.RoleMap;
}

const isSequential = (data: number[]): boolean => {
    for (let i = 1, l = data.length; i < l; i++) {
        if (data[i - 1] + 1 !== data[i]) return false;
    }
    return true;
}

export interface PhoneNumber {
    first: string;
    middle: string;
    last: string;
}

const templates = {};
export const TemplateManager = {
    getTemplate: function (name): FieldElementConcreteDefinition {
        const template = templates[name];
        if (template) {
            return Object.assign({ id: name }, template);
        }
        throw new Error("undefined field box template: " + name);
    },
    addTemplate: function (name, data) {
        templates[name] = data;
    },
    addTemplates: function (definitions) {
        for (let i in definitions) {
            if (!definitions.hasOwnProperty(i)) continue;
            templates[i] = definitions[i];
        }
    },
    startsWithJapaneseMultibyteCharacter: function (str) {
        return /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]/.exec(str);
    },
    isKatakanaString: function (str) {
        if (typeof str !== "string") {
            return false;
        }
        return /^[ァ-ヶー]+$/.exec(str);
    },
    isNumberString: function (str) {
        if (typeof str !== "string") {
            return false;
        }
        return /^[0-9０-９]+$/.exec(str);
    },
    createValidatorStartWithJapaneseCharacter: function () {
        const self = this;
        return function () {
            const errors = [];
            if (!self.startsWithJapaneseMultibyteCharacter(JSON.parse(this.value))) {
                errors.push("正しく入力してください");
            }
            return errors;
        };
    },
    Util: {
        startsWithJapaneseMultibyteCharacter: function (str) {
            return /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]/.exec(str);
        },
        isKatakanaString: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[ァ-ヶー]+$/.exec(str);
        },
        isHiraganaString: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[ぁ-んー]+$/.exec(str);
        },
        isNumberString: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[0-9０-９]+$/.exec(str);
        },
        isEMailString: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+$/.exec(str);
        },
        isEMailDomainString: function (str) {
            if (typeof str !== "string") {
                return false;
            }
            return /[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.exec(str);
        },
        createValidatorStartWithJapaneseCharacter: function () {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.startsWithJapaneseMultibyteCharacter(value)) {
                    errors.push("正しく入力してください");
                }
                return errors;
            };
        },
        createValidatorNotEmptyStartWithJapaneseCharacter: function (label) {
            const self = this;
            return function (value) {
                const errors = [];
                if (!value) {
                    errors.push(`${label}を入力してください`)
                }
                if (!self.startsWithJapaneseMultibyteCharacter(value)) {
                    errors.push(`${label}を正しく入力してください`);
                }
                return errors;
            };
        },
        createValidatorKatakana: function (label: string = "") {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isKatakanaString(value)) {
                    if (label) {
                        errors.push(`${label}を全角カタカナで入力してください`);
                    } else {
                        errors.push(`全角カタカナで入力してください`);
                    }
                }
                return errors;
            };
        },
        createValidatorHiragana: function (label) {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isHiraganaString(value)) {
                    errors.push(`${label}をひらがなで入力してください`);
                }
                return errors;
            };
        },
        createValidatorDigit: function (label = "", length = 0) {
            return this.createValidatorDigitPattern(label, length === 0 ? [] : [length]);
        },
        createValidatorDigitPattern: function (label = "", pattern: number[] = []) {
            return (value: string) => {
                const errors: string[] = [];
                if (!this.isNumberString(value) || (pattern.length !== 0 && pattern.indexOf(value.length) === -1)) {
                    if (label && pattern.length !== 0) {
                        errors.push(`${label}${pattern.join("ケタまたは")}ケタを数字で入力してください`);
                    } else if (label) {
                        errors.push(`${label}を数字で入力してください`);
                    } else {
                        if (pattern.length > 0) {
                            if (pattern.length > 1 && isSequential(pattern)) {
                                errors.push(`${pattern[0]}ケタから${pattern[pattern.length - 1]}ケタの数字で入力してください`);
                            } else {
                                errors.push(`${pattern.join("ケタまたは")}ケタの数字で入力してください`);
                            }
                        } else {
                            errors.push(`数字で入力してください`);
                        }
                    }
                }
                return errors;
            };
        },
        createValidatorEMail: function (label) {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isEMailString(value)) {
                    errors.push(`正しく入力してください。`);
                }
                return errors;
            };
        },
        createValidatorEMailDomain: function (label) {
            const self = this;
            return function (value) {
                const errors = [];
                if (!self.isEMailDomainString(value)) {
                    errors.push(`ドメインを正しく入力してください。`);
                }
                return errors;
            };
        },
        convertToASCIIDigit(str) {
            const charCodeOffset = "０".charCodeAt(0) - "0".charCodeAt(0);
            return str.replace(/[０-９]/g, (s) => {
                return String.fromCharCode(s.charCodeAt(0) - charCodeOffset);
            });
        },
        parsePhoneNumber: (value: string): PhoneNumber | null => {
            if (!value) return null;
            const patterns = [
                /^\((\d\d+)\)-?(\d\d+)-(\d\d\d\d)$/,
                /^(\d\d\d)-(\d\d\d\d)-(\d\d\d\d)$/,
                /^(\d\d\d)-(\d\d\d)-(\d\d\d\d)$/
            ];
            for(let i = 0; i < patterns.length; i++) {
                const result = matchPhoneNumber(patterns[i], value);
                if (result) return result;
            }
            return null;
        }
    }
};

const matchPhoneNumber = (regex: RegExp, value: string): PhoneNumber | null => {
    const matched = regex.exec(value);
    if (matched && matched.length === 4) {
        return {
            first: matched[1],
            middle: matched[2],
            last: matched[3]
        };
    }
    return null;
};

import * as validator from "./validator";

export const PhoneNumberValidator: validator.ValidatorDefinition<PhoneNumber> = {
    properties: {
        first: TemplateManager.Util.createValidatorDigitPattern(null, [2, 3, 4]),
        middle: TemplateManager.Util.createValidatorDigitPattern(null, [2, 3, 4]),
        last: TemplateManager.Util.createValidatorDigitPattern(null, [4])
    },
    all: (value: PhoneNumber) => {
        return [10, 11].includes(value.first.length + value.middle.length + value.last.length) ? [] : ["10ケタから11ケタの数字で入力してください"];
    }
};
    
import { FieldInputElement } from "./DigitalForm/Element/FieldInputElement";
import { FormTextElement } from "./DigitalForm/Element/FormTextElement";
import { FormRadioElement } from "./DigitalForm/Element/FormRadioElement";
import { FormSelectElement } from "./DigitalForm/Element/FormSelectElement";
import { FormNumberElement } from "./DigitalForm/Element/FormNumberElement";
import { FieldDomElement } from "./DigitalForm/FieldDomElement";
import { FieldCompositeElement } from "./DigitalForm/FieldCompositeElement"
import { FormElement } from "./DigitalForm/Element/FormElement";
import { FormTextAreaElement } from "./DigitalForm/Element/FormTextAreaElement";
import { FormCheckBoxElement } from "./DigitalForm/Element/FormCheckBoxElement";
import { FieldInputContainer, findFieldInput } from "./DigitalForm/Element/FieldInputContainer";
import { FormZipCodeElement, ZipcodeReferenceType } from "./DigitalForm/Element/FormZipCodeElement";
import { FormPhoneNumberElement } from "./DigitalForm/Element/FormPhonenumberElement";

function setupFormElement<T>(definition: ElementInterface, element: FormElement<T>) {
    if (definition.title) {
        element.title = definition.title;
    }
    if (definition.description) {
        element.description = definition.description;
    }
    if (definition.required) {
        element.required = definition.required;
    }
}

function setupPreSuffix(definition: TextElementDefinition | NumberElementDefinition, element: FormTextElement | FormNumberElement) {
    if (definition.prefix) {
        element.prefixElement.textContent = definition.prefix;
    }
    if (definition.suffix) {
        element.suffixElement.textContent = definition.suffix;
    }
}

const buildValidator = (validator: Validator): (valiue: any) => any[] => {
    if (validator === "StartWithJapanese") {
        return TemplateManager.Util.createValidatorStartWithJapaneseCharacter();
    }
    if (validator === "NumberOnly") {
        return TemplateManager.Util.createValidatorDigit();
    }
    if (validator === "KatakanaOnly") {
        return TemplateManager.Util.createValidatorKatakana();
    }
    if (validator.kind === "SpecifiedLengthDigitsOnly") {
        return TemplateManager.Util.createValidatorDigitPattern("", validator.pattern);
    }
};

function buildTextElement(definition: TextElementDefinition, template: DOMTemplate): FormTextElement {
    const formElement = new FormTextElement(definition.id, template);
    setupFormElement(definition, formElement);
    setupPreSuffix(definition, formElement);
    if (definition.min !== undefined) {
        formElement.textElement.minLength = definition.min;
    }
    if (definition.max !== undefined) {
        formElement.textElement.maxLength = definition.max;
    }
    if (definition.placeholder) {
        formElement.textElement.placeholder = definition.placeholder;
    }
    if (definition.validator) {
        formElement.validator = buildValidator(definition.validator);
    }
    if (definition.class) {
        formElement.fieldsElement.classList.add(definition.class);
    }
    if (definition.readonly) {
        formElement.textElement.readOnly = definition.readonly;
    }
    return formElement;
}

function buildRadioElement(definition: RadioElementDefinition, template: DOMTemplate): FormRadioElement {
    const element = new FormRadioElement(definition.id, definition.options, template);
    setupFormElement(definition, element);
    return element;
}

function buildCheckBoxElement(definition: CheckBoxElementDefinition, template: DOMTemplate): FormCheckBoxElement {
    const element = new FormCheckBoxElement(definition.id, definition.options, template);
    setupFormElement(definition, element);
    return element;
}

function buildSelectElement(definition: SelectElementDefinition, template: DOMTemplate): FormSelectElement {
    const element = new FormSelectElement(definition.id, definition.options, template);
    setupFormElement(definition, element);
    return element;
}

function buildNumberElement(definition: NumberElementDefinition, template: DOMTemplate): FormNumberElement {
    const element = new FormNumberElement(definition.id, template);
    setupFormElement(definition, element);
    setupPreSuffix(definition, element);
    if (definition.min !== undefined) {
        element.min = definition.min;
    }
    if (definition.max !== undefined) {
        element.max = definition.max;
    }
    if (definition.initialValue) {
        element.initialValue = definition.initialValue;
    }
    return element;
}

function buildTextAreaElement(definition: TextAreaElementDefinition, template: DOMTemplate): FormTextAreaElement {
    const element = new FormTextAreaElement(definition.id, template);
    setupFormElement(definition, element);
    if (definition.placeholder) {
        element.textAreaElement.placeholder = definition.placeholder;
    }
    if (definition.maxLength) {
        element.textAreaElement.maxLength = definition.maxLength;
    }
    return element;
}

function buildZipCodeElement(definition: ZipCodeElementDefinition, template: DOMTemplate): FormZipCodeElement {
    const element = new FormZipCodeElement(definition.id, template);
    setupFormElement(definition, element);
    return element;
}

const buildPhoneNumberElement = (definition: PhoneNumberElementDefinition, template: DOMTemplate): FormPhoneNumberElement => {
    const element = new FormPhoneNumberElement(definition.id, template);
    setupFormElement(definition, element);
    return element;
};

function buildFieldDomElement(definition: FieldDomTemplate, template: DOMTemplate): FieldDomElement {
    const element = new FieldDomElement(definition.id, definition.domTemplate, template);
    element.validator = definition.validate;
    element.calculateValue = definition.value;
    element.deserializeValue = definition.setValue;
    setupFormElement(definition, element);
    if (definition.manualUpdate) {
        definition.manualUpdate(element);
    } else {
        element.mapKeys.forEach(key => {
            element.registerInput(element.subInput(key));
        });
    }
    if (definition.setup) {
        definition.setup(element, definition);
        element.update();
    }
    if (definition.extraSetup) {
        definition.extraSetup(element, definition);
    }
    return element;
}

function buildFieldCompositeElement(definition: FieldObjectDefinition, rootContainer: FieldInputContainer, template: DOMTemplate): FieldCompositeElement {
    let id = definition.id;
    const index = definition.index;
    if (index !== undefined) {
        id = `${id}[${index}]`;
    }

    const element = new FieldCompositeElement(id, template);
    element.calculateValue = definition.value;
    setupFormElement(definition, element);
    const fields = definition.fields;
    for (let i = 0, l = fields.length; i < l; i++) {
        const field = (() => {
            const f = Object.assign({}, resolveTemplateReference(fields[i]));
            if (index === undefined) return f;
            f.id = `${f.id}[${index}]`;
            return f;
        })();
        const subInput = buildInput(field, rootContainer, element, template);
        element.appendFieldInput(subInput);
    }
    if (definition.setup) {
        definition.setup(element, definition);
        element.update();
    }
    if (definition.extraSetup) {
        definition.extraSetup(element, definition);
    }
    return element;
}

export function createFieldInputElements(definition: FieldElementConcreteDefinition, rootContainer: FieldInputContainer, template: DOMTemplate): FieldInputElement {
    switch (definition.kind) {
        case "field":
            return buildFieldCompositeElement(definition, rootContainer, template);
        case "text":
            return buildTextElement(definition, template);
        case "radio":
            return buildRadioElement(definition, template);
        case "checkbox":
            return buildCheckBoxElement(definition, template);
        case "select":
            return buildSelectElement(definition, template);
        case "number":
            return buildNumberElement(definition, template);
        case "textarea":
            return buildTextAreaElement(definition, template);
        case "zipcode":
            return buildZipCodeElement(definition, template);
        case "phone_number":
            return buildPhoneNumberElement(definition, template);
        case "dom":
            return buildFieldDomElement(definition, template);
    }
}

export function resolveTemplateReference(reference: FieldElementDefinition): FieldElementConcreteDefinition {
    if (reference.kind !== "template") return reference;
    const definition: FieldElementConcreteDefinition = TemplateManager.getTemplate(reference.template);
    const copy = Object.assign({}, reference);
    delete copy.template;
    delete copy.kind;
    Object.assign(definition, copy);
    return definition;
}

export function buildFieldElement(definition: FieldElementDefinition, rootContainer: FieldInputContainer, template: DOMTemplate): FieldInputElement {
    switch (definition.kind) {
        case "template":
            return createFieldInputElements(resolveTemplateReference(definition), rootContainer, template);
        default:
            return createFieldInputElements(definition, rootContainer, template);
    }
}

export function buildInput(field: FieldElementDefinition, rootContainer: FieldInputContainer, parentContainer: FieldInputContainer, template: DOMTemplate): FieldInputElement {
    let min = 1;
    let max = 1;
    let variableNumber = false;
    if (field.cardinality) {
        if (typeof field.cardinality === "number") {
            min = field.cardinality;
            max = field.cardinality;
        } else if (typeof field.cardinality === "string") {
            const bind = field.cardinality;
            const fieldInput = findFieldInput(rootContainer, bind);
            const value = fieldInput.virtualInput.value;
            if (value && value !== "undefined") {
                min = JSON.parse(value);
            } else {
                min = 1;
            }

            max = min;
            const callback = () => {
                const input = buildInput(field, rootContainer, rootContainer, template);
                rootContainer.updateFieldInput(input, field.id);
                fieldInput.virtualInput.removeEventListener("change", callback);
            };
            fieldInput.virtualInput.addEventListener("change", callback);
            variableNumber = true;
        } else {
            if (field.cardinality.min) {
                min = field.cardinality.min;
            }
            if (field.cardinality.max) {
                max = field.cardinality.max;
            }
        }
    }
    const definition = (() => {
        if (!variableNumber) return field;
        const fieldDefinition: FieldObjectDefinition = {
            kind: "field",
            id: field.id,
            fields: []
        };
        for (let j = 0; j < min; j++) {
            const elementField = Object.assign({}, field);
            delete elementField.cardinality;
            elementField.index = j;
            fieldDefinition.fields.push(elementField);
        }
        return fieldDefinition;
    })();
    const fieldInput = buildFieldElement(definition, rootContainer, template);
    if (field.condition) {
        const { targetId, targetValue } = (() => {
            if (typeof field.condition === "string") {
                return { targetId: field.condition, targetValue: [field.id] };
            }
            if (typeof field.condition.value === "string") {
                return { targetId: field.condition.id, targetValue: [field.condition.value] };
            }
            return { targetId: field.condition.id, targetValue: field.condition.value };
        })();
        const targetInput = findFieldInput(rootContainer, targetId) || findFieldInput(parentContainer, targetId);
        const callback = () => {
            const parsed = targetInput.value;
            if (targetInput instanceof FormCheckBoxElement) {
                const values: string[] = parsed;
                fieldInput.disabled = fieldInput.hidden = values.every(value => !targetValue.includes(value));
            } else if (targetInput instanceof FormRadioElement) {
                fieldInput.disabled = fieldInput.hidden = !targetValue.includes(parsed);
            } else if (targetInput instanceof FormNumberElement) {
                fieldInput.disabled = fieldInput.hidden = !targetValue.includes(parsed)
            } else {
                throw new Error("unsupported condition");
            }
        };
        targetInput.virtualInput.addEventListener("change", callback);
        callback();
    }
    if (field.refer) {
        const targetToken = field.refer.split("-");
        const targetId = targetToken[0];
        const targetInput = findFieldInput(rootContainer, targetId) || findFieldInput(parentContainer, targetId);
        if (targetInput instanceof FormZipCodeElement) {
            targetInput.addReferer(fieldInput, targetToken.length === 1 ? "raw" : <ZipcodeReferenceType>targetToken[1]);
        } else {
            console.log(field);
            throw new Error(`invalid reference: ${field.refer}`);
        }
    }
    if (field.hidden) {
        fieldInput.hidden = true;
    }
    return fieldInput;
}

export function buildFieldBox(data: DigitalFormBoxDefinition, template: DOMTemplate): FieldBox {
    const box = new FieldBox(data.id, template);
    if (data.title) {
        box.title = data.title;
    }
    if (data.required) {
        box.isRequired = data.required;
    }
    if (data.description) {
        box.description = data.description;
    }
    if (data.hidden) {
        box.hidden = data.hidden;
    }
    data.elements.forEach(definition => box.appendFieldInput(buildInput(definition, box, box, template)));
    return box;
}

export function buildDigitalForm(data: DigitalFormDefinition, template: DOMTemplate): DigitalForm {
    const form = new DigitalForm(data.id, template);
    if (data.title) {
        form.title = data.title;
    }
    data.boxes.forEach(definition => form.appendBox(buildFieldBox(definition, template)));
    if (form.numberOfBoxes > 0) {
        form.boxAtIndex(0).open();
    }
    return form;
}


export type FormElementPath = [DigitalForm, string];

const isNotNull = (data: any): boolean => data !== null;

const resolvePath = (path: FormElementPath): FieldInputElement => {
    if (!path[0]) return null;
    return findFieldInput(path[0], path[1]);
}

export interface SyncHandler {
    elements: FieldInputElement[];
    exec: (target: FieldInputElement) => void;
}

const createSyncHandler = (paths: FormElementPath[]): SyncHandler => {
    const elements = paths.filter(path => path[0]).map(resolvePath).filter(isNotNull);
    let syncing = false;
    const context = {
        elements,
        exec: (target: FieldInputElement) => {
            if (syncing || target.disabled) return;
            elements.filter(e => e !== target).forEach(e => {
                syncing = true;
                e.value = target.value;
                syncing = false;
            });
        }
    };
    return context;
}

export const conditionalSync = (conditionPath: FormElementPath, target: any, ...paths: FormElementPath[]) => {
    const handler = createSyncHandler(paths);
    const conditionField = resolvePath(conditionPath);
    if (!conditionField) return;
    conditionField.virtualInput.addEventListener("change", () => {
        if (conditionField.value === target) {
            handler.exec(resolvePath(paths[0]));
        }
    });
    handler.elements.forEach(e => {
        e.virtualInput.addEventListener("change", () => {
            if (conditionField.value !== target) {
                return;
            }
            handler.exec(e);
        });
    });
};

export const sync = (...paths: FormElementPath[]) => {
    const handler = createSyncHandler(paths);
    handler.elements.forEach(e => {
        e.virtualInput.addEventListener("change", () => {
            handler.exec(e);
        });
    });
    return handler;
};

export type FormMap = { [keys: string]: DigitalForm };
export type FormDefinitionMap = { [keys: string]: DigitalFormDefinition };

const hasRole = (definition: DigitalFormDefinition, role: string): boolean => {
    return getPersonaByRole(definition, role) !== undefined;
}

const getRoles = (definition: DigitalFormDefinition): string[] => {
    return definition.persona ? Object.keys(definition.persona) : [];
}

const getRolesFromMap = (map: FormDefinitionMap): string[] => {
    return [...new Set(Object.values(map).flatMap(definition => {
        return getRoles(definition);
    }))];
};

const getPersonaByRole = (definition: DigitalFormDefinition, role: string): Persona => {
    return definition.persona ? definition.persona[role] : undefined;
};

const getMutualPersonaProperties = (personaList: Persona[]): string[] => {
    return [...new Set(personaList.flatMap(persona => Object.keys(persona)))];
};

const getMutualPersonaPropertiesForRole = (map: FormDefinitionMap, role: string): string[] => {
    const personaList = Object.values(map).flatMap(definition => {
        const persona = getPersonaByRole(definition, role);
        return persona !== undefined ? [persona] : [];
    });
    return getMutualPersonaProperties(personaList);
};

const linkPersonaByRole = (formMap: FormMap, definitionMap: FormDefinitionMap, role: string) => {
    const formIds = Object.keys(formMap);
    getMutualPersonaPropertiesForRole(definitionMap, role).forEach(property => {
        const linkProperties = formIds.flatMap(id => {
            const form = formMap[id];
            const definition = definitionMap[id];
            if (!hasRole(definition, role)) {
                return [];
            }
            return [[form, definition.persona[role][property]] as FormElementPath];
        });
        sync(...linkProperties);
    });
};

export const linkPersona = (formMap: FormMap, definitionMap: FormDefinitionMap) => {
    const roles = getRolesFromMap(definitionMap);
    roles.forEach(role => {
        linkPersonaByRole(formMap, definitionMap, role);
    });
};