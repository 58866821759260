import { FieldInputElement } from "./Element/FieldInputElement";
import { FormElement } from "./Element/FormElement";
import { DOMTemplate } from "../denki";

export abstract class FieldInputElementWrapper extends FormElement implements FieldInputElement {
  public readonly elementsWrapper: HTMLElement;
  protected _disabled: boolean;

  public constructor(name: string, template: DOMTemplate) {
    super(name, template.get("field-element"));
    const id = name;
    const element = this.element;

    const elementsWrapper = <HTMLElement>element.querySelector("[data-role=elements]");
    this.elementsWrapper = elementsWrapper;

    Object.defineProperty(this.element, 'value', {
      get: () => this.value,
      set: (value) => {
        if (elementsWrapper.querySelectorAll("input").length === 1) {
          const input = elementsWrapper.querySelector("input");
          input.value = value;
          const e2 = document.createEvent("HTMLEvents");
          e2.initEvent("change", false, true);
          input.dispatchEvent(e2);
          return;
        }
      }
    });
  };

  abstract get validationErrors();

  protected updateValidation() {
    const value = this.value;
    let errors = this.validationErrors;
    this.titleElement.classList.remove("completed");
    if (errors.length > 0) {
      this.errors.textContent = errors[0];
      this.errors.style.display = "";
      this.errors.removeAttribute('style');
    } else {
      this.errors.textContent = "";
      this.errors.style.display = "none";
      if (this.required || value) {
        this.titleElement.classList.add("completed");
      }
    }
    const inputs = this.elementsWrapper.querySelectorAll("input");
    if (inputs.length === 1) {
      if (errors.length > 0) {
        inputs[0].classList.add("error");
      } else {
        inputs[0].classList.remove("error");
      }
    }
  }

  public update = () => {
    const value = this.value;
    this.virtualInput.value = JSON.stringify(value);
    this.updateValidation();
    this.dispatchChangeEvent();
  };

  private updateWithChangeEvent = (e) => {
    this.update();
    const e2 = document.createEvent("HTMLEvents");
    e2.initEvent("change", false, true);
    e.target.dispatchEvent(e2);
  };

  public registerInput(input: HTMLElement) {
    input.addEventListener('keyup', this.updateWithChangeEvent);
    input.addEventListener('click', this.updateWithChangeEvent);
    input.addEventListener('change', this.update);
  }

  public unregisterInput(input: HTMLElement) {
    input.removeEventListener('keyup', this.updateWithChangeEvent);
    input.removeEventListener("click", this.updateWithChangeEvent);
    input.removeEventListener("change", this.update);
  }

  get hidden() {
    return this.element.style.display === "none";
  }

  set hidden(value) {
    if (value) {
      this.element.style.display = "none";
    } else {
      this.element.style.display = "";
      this.element.removeAttribute("style");
    }
  }

  set disabled(disabled) {
    this._disabled = disabled;
    this.update();
  }

  get disabled() {
    return this._disabled;
  }

  get value() {
    return this._disabled ? null : this.calcValue();
  }

  set value(value: any) {

  }

  abstract calcValue();

  abstract focus();
}