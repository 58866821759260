// @ts-ignore
const DEBUG = __ENV__ === "development";
import "./styles/main.scss";
import * as megane from '../node_modules/meganets/dist/megane.js';
import { DOMTemplate } from './denki'
import { DigitalApplicationDefinition, buildDigitalForm, ExtraPageDefinition, DigitalFormDefinition } from './digitalForm'
import { DigitalForm } from "./DigitalForm/DigitalForm";
import { DataBindManager, HTMLElementBindDataStorage, BindDataStorage, BindDataSource } from './bind';
import * as Bowser from "bowser/bundled";
import QRCode from "qrcode";
import pako from "pako";

const PDF_FILE_NAME = "書類";
const PDF_META_TITLE = "書類";

export class DigitalFormEditorApplication extends megane.SinglePageApplication {
    private readonly formData: DigitalApplicationDefinition;
    private _templates;
    private _currentViewController: megane.ViewController;
    private hasData: boolean = false;
    private savedData = null;

    constructor(formData, frame, templates) {
        super(frame);
        this.formData = formData;
        this._templates = templates;
        window.addEventListener('popstate', (e) => {
            const token = location.hash.split("/");
            if (token[0] !== "#forms" && this.hasData) {
                if (isiOS) {
                    setTimeout(() => {
                        Notifier.confirm(ARE_YOU_SURE_TO_LEAVE_MESSAGE, result => {
                            if (result) {
                                this.hasData = false;
                            } else {
                                window.history.forward();
                            }
                        });
                    }, 0);
                } else {
                    if (confirm(ARE_YOU_SURE_TO_LEAVE_MESSAGE)) {
                        this.hasData = false;
                    } else {
                        window.history.forward();
                    }
                }
            }
        });
        window.addEventListener("load", () => {
            const builds = document.querySelectorAll('.debug-info');
            for (let i = 0; i < builds.length; i++) {
                // @ts-ignore
                builds[i].textContent = "build: " + __BUILD_ID__;
            }
            // app.onAppReady();

            //iOS13のiPad対応
            if (isiPad13) {
                document.body.classList.add("tablet");
            } else {
                document.body.classList.add(browser.parsePlatform().type);
            }
        });
    }

    viewControllerForHash(hash) {
        window.scrollTo(0, 0);
        let token = hash.split("/");
        if (token[0] === "#" || token[0] === "") {
            try {
                let view = this._templates.get("acknowledgement-view");
                const viewController = new AcknowledgementViewController(view);
                viewController.onGotoFormButtonClick = (args) => {
                    this.savedData = args;
                    location.href = "#forms";
                };
                return viewController;
            } catch (e) {
                this.savedData = { _: "save" };
                location.href = "#forms";
                return;
            }
        } else if (token[0] === "#forms" || token[0] === "#qr") {
            if (!this.hasData) {
                if (!DEBUG && !this.savedData && token[0] !== "#qr") {
                    location.hash = "#";
                    return;
                }
                const view = this._templates.get("form-view");
                this._currentViewController = new FormViewController(this.formData, view, this.savedData);
                this.hasData = true;
            }
            const viewController: FormViewController = this._currentViewController;
            const page = (() => {
                if (token.length === 1) return 0;
                return parseInt(token[1]) - 1;
            })();
            if (viewController) {
                viewController.changeForm(page);
            }
            if (token.length === 3 && token[2] === "preview") {
                const index = token[1] + "-1";
                viewController.savePDF(true, index);

                const timeout = 3000;
                let start = Date.now();
                const fn = () => {
                    const height = viewController.getPDFPreviewContentHeight();
                    if (height > 0) {
                        document.body.style.height = Math.ceil(height) + "px";
                    } else if (Date.now() - start < timeout) {
                        setTimeout(fn, 200);
                    }
                };
                setTimeout(fn, 200);
            }
            if (token[0] === "#qr") {
                viewController.showReadQRDialog();
            }
            viewController.onFinishPreview = this.formData.onFinishPreview;
            return viewController;
        } else if (token[0] === "#require") {
            return new megane.ViewController(this._templates.get("requirement-view"));
        } else if (token[0] === "#others") {
            return new megane.ViewController(this._templates.get("others-view"));
        }
        return super.viewControllerForHash(hash);
    }
}

class AcknowledgementViewController extends megane.ViewController {
    constructor(view) {
        super(view);
        const paymentTypeNew = view.querySelector("[name=payment-type]");
        paymentTypeNew.checked = true;
        const agree = view.querySelector(".agree-check");
        const nodebt = view.querySelector(".no_debt-check");
        const gotoFormButton = view.querySelector(".goto-form");
        gotoFormButton.addEventListener("click", (e) => {
            e.preventDefault();
            if (!agree.checked) {
                Notifier.show("入会申込にあたっては、事前に申し込みを行う児童クラブで説明を受ける必要があります");
                return;
            }
            if (!nodebt.checked) {
                Notifier.show("放課後児童クラブ利用料の滞納（未納）がある方は、入会申込できません。\n" +
                    "こども青少年課こども育成係(学童保育利用料担当)(電話：078-322-6392)までご相談ください。");
                return;
            }
            const gotoForm = () => {
                const paymentType = view.querySelector("[name=payment-type]:checked");
                const data = {
                    "application-payment_type": paymentType.value,
                    "application-payment_id": paymentType.id,
                };
                if (!isValidBrowser) {
                    data["application-unsupported_browser"] = "△サポート外ブラウザ使用: " + systemInfo;
                }
                this.onGotoFormButtonClick(data);
            };
            if (isValidBrowser) {
                gotoForm();
            } else {
                Notifier.show(UNSUPPORTED_BROWSER_MESSAGE, (ok) => {
                    if (ok) { gotoForm(); }
                });
            };
        });
    }

    didShowView(navigationController) {
        if (!isValidBrowser) {
            document.body.classList.add('unsupported');
            Notifier.show(UNSUPPORTED_BROWSER_MESSAGE);
        }
        //iOS13 iPad対応
        if (isiPad13) {
            document.body.classList.add("tablet");
        } else {
            document.body.classList.add(browser.parsePlatform().type);
        }
    }

    public onGotoFormButtonClick: (object) => void = function () { };
}

import { QRCodeReader, QRResult } from './ui/QRCodeReader';
import { DigitalFormDataSource } from "./DigitalForm/DigitalFormDataSource";
import { findFieldInput, getFieldInputData, getFieldInputKeys } from "./DigitalForm/Element/FieldInputContainer";

interface DynamicPageDefinition {
    kind: "dynamic";
    generate: () => Promise<HTMLIFrameElement>;
}

interface FormPageDefinition {
    kind: "form";
    index: PDFPreviewIndexSet;
}

type PageDefinition = FormPageDefinition | DynamicPageDefinition | ExtraPageDefinition;

type SerializedPDFPreviewIndex = string | number;
type SerializedPDFPreviewIndexRepresentation = null | SerializedPDFPreviewIndex | SerializedPDFPreviewIndex[];

class FormViewController extends megane.ViewController {
    private readonly view;
    private readonly paperFormFrames;
    public readonly digitalFormViewController: DigitalFormViewController;
    private formData: DigitalApplicationDefinition;
    private navButtons: HTMLElement[];
    private currentFormIndex?: number;
    private pdfPreviewWindow: PDFPreviewWindow;
    private pdfCanceled: boolean;
    private pdf;
    private needsAccountTransfer: boolean;

    private nextFormButton;
    private prevFormButton;
    private changePageButton;

    private binder: DataBindManager;
    private isBinding: boolean = false;
    private currentScale: number = 1.0;
    private mainWindow: HTMLDivElement;
    private extraPages: ExtraPageDefinition[] = [];
    public onFinishPreview?: () => void;
    private isQREnabled: boolean;
    private bindInterceptor: BindDataStorage;

    constructor(formData: DigitalApplicationDefinition, view, data) {
        super(view);
        this.view = view;
        this.formData = formData;
        const allForms = [];
        const applicationWindow = view.getElementsByClassName("application-window")[0];
        const mainWindow = view.getElementsByClassName("main-content")[0];
        this.mainWindow = mainWindow;
        const nav = mainWindow.querySelector("nav");
        for (let i = 0, l = formData.forms.length; i < l; i++) {
            const form = formData.forms[i];
            const rendering = formData.forms[i].rendering;
            const iframe = document.createElement("iframe");
            iframe.setAttribute("sandbox", "allow-modals allow-same-origin allow-scripts");
            iframe.setAttribute("style", "display: none;");
            iframe.setAttribute("data-num-pages", String(rendering.numberOfPages));
            iframe.src = rendering.path;
            applicationWindow.appendChild(iframe);
            allForms.push(iframe);

            const item = document.createElement("button");
            item.setAttribute("data-form", String(i + 1));
            item.setAttribute("role", "tab");
            item.setAttribute("aria-selected", i === 0 ? "true" : "false");
            if (i === 0) item.setAttribute("selected", "true");
            item.textContent = `${i + 1}. ${form.title}`;
            nav.appendChild(item);
        }
        this.paperFormFrames = allForms;

        this.needsAccountTransfer =
            !(data &&
                (data["application-payment_id"] === "bycache" ||
                    data["application-payment_id"] === "no"));
        if (!this.needsAccountTransfer) {
            this.paperFormFrames.splice(2, 1);
        }

        const digitalFormView = view.getElementsByClassName("main-content")[0];
        this.digitalFormViewController = new DigitalFormViewController(formData, digitalFormView, data);
        const forms = this.digitalFormViewController.digitalForms;
        forms.forEach(form => {
            for (let i = 0, l = form.numberOfBoxes; i < l; i++) {
                const box = form.boxAtIndex(i);
                box.onUpdateFieldInput = () => {
                    this.rebind();
                }
            }
        });

        const extraPages = formData.extraPages;
        if (extraPages) {
            this.extraPages = extraPages;
        }

        if (DEBUG) {
            const footer = view.getElementsByTagName("footer")[0];

            const exportButton = document.createElement("button");
            exportButton.setAttribute('debug', '');
            exportButton.textContent = "エクスポート";
            exportButton.addEventListener("click", () => {
                const sourceElement = document.createElement("textarea");
                document.body.appendChild(sourceElement);
                const clone = this.paperFormFrames[this.currentFormIndex].contentWindow.document.documentElement.cloneNode(true);
                const clones = clone.querySelectorAll("[data-mark=clone]");
                for (let i = 0, l = clones.length; i < l; i++) {
                    clones[i].parentNode.removeChild(clones[i]);
                }
                const htmlContent = '<!doctype html>\n' + clone.outerHTML;
                sourceElement.textContent = htmlContent.replace(/ style=""/g, "");
                const range = document.createRange();
                range.selectNodeContents(sourceElement);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);

                sourceElement.setSelectionRange(0, sourceElement.textContent.length);

                if (document.execCommand("copy", false, null)) {
                    alert("クリップボードにコピーしました");
                }
                document.body.removeChild(sourceElement);
            });
            footer.appendChild(exportButton);
        }

        const templates = document.getElementById("template");
        const previewWindow = templates.getElementsByClassName("pdf-preview-window")[0].cloneNode(true);
        this.pdfPreviewWindow = new PDFPreviewWindow(previewWindow, this.view);
        this.pdfPreviewWindow.onSave = () => {
            if (this.pdf) {
                if (isiOS) {
                    const result = this.pdf.output("blob", { filename: this.getPDFFilename() });
                    window.open(URL.createObjectURL(result));
                } else {
                    this.pdf.save(this.getPDFFilename());
                }
            }
        };
        this.pdfPreviewWindow.onCancel = () => {
            this.pdfCanceled = true;
            if (!this.pdfPreviewWindow.isHidden()) {
                this.pdfPreviewWindow.hide();
            }
        };
        this.pdfPreviewWindow.onClose = () => {
            if (this.onFinishPreview) {
                this.onFinishPreview();
            }
        };
        this.pdf = null;

        const confirmBrowserSupport = (callback: () => void) => {
            if (isValidBrowser) {
                callback();
            } else {
                Notifier.show(UNSUPPORTED_BROWSER_MESSAGE, (ok) => {
                    if (ok) { callback(); }
                });
            }
        };

        let onSavePDF: (form: FormViewController) => void;
        Array.prototype.slice.call(view.querySelectorAll(".save-pdf")).forEach((elm) => {
            elm.addEventListener("click", (e) => {
                confirmBrowserSupport(() => {
                    if (onSavePDF) {
                        onSavePDF(this);
                    }
                    this.savePDF(true);
                });
            });
        });

        const readQRButton = view.querySelector(".read-qr");
        if (readQRButton) {
            readQRButton.addEventListener("click", async () => {
                this.readQRCode();
            });
        }

        let onSaveQRCode: (form: FormViewController) => void;
        const showQRButton = view.querySelector(".show-qr");
        this.isQREnabled = !!showQRButton;
        if (showQRButton) {
            showQRButton.addEventListener("click", () => {
                confirmBrowserSupport(() => {
                    if (onSaveQRCode) {
                        onSaveQRCode(this);
                    }
                    this.saveQRCode();
                });
            });

        }

        this.nextFormButton = view.querySelector(".next-form");
        this.nextFormButton.addEventListener("click", (e) => {
            location.hash = '#forms/' + ((this.currentFormIndex + 1) % this.paperFormFrames.length + 1);
        });

        this.prevFormButton = view.querySelector(".prev-form");
        this.prevFormButton.addEventListener("click", (e) => {
            location.hash = '#forms/' + ((this.currentFormIndex - 1) % this.paperFormFrames.length + 1);
        });

        this.changePageButton = view.querySelector(".change-page");
        this.changePageButton.addEventListener("click", (e) => {
            if (this.isBinding) {
                return;
            }
            const iframe = this.paperFormFrames[this.currentFormIndex];
            let page = +iframe.getAttribute("data-page");
            let num = +iframe.getAttribute("data-num-pages");
            this.changePage(page >= num ? 1 : (page + 1));
        });

        // Navigation buton
        const buttons = view.querySelectorAll("nav > button");
        this.navButtons = [];
        for (let i = 0; i < buttons.length; ++i) {
            const button = buttons[i];
            if (this.paperFormFrames.indexOf(allForms[i]) === -1) {
                button.style.display = "none";
            } else {
                button.addEventListener("click", () => {
                    location.hash = '#forms/' + button.getAttribute("data-form");
                });
                this.navButtons.push(button);
            }
        }
        //雑に拡大縮小を実装した
        //iframeにtransformかけるとめんどくさいと思い中身を直接transformかける野蛮方式
        // 課題1. フォームをきりかえるたびにscaleをリセットするか現状のscaleにする
        // 課題2. 初回起動時にiframeの幅に合わせて拡大縮小する
        const zoom_button = view.querySelector("#zoom");
        zoom_button.addEventListener('click', (e) => {
            if (this.currentScale < 5.0) {
                this.currentScale += 0.1;
                this.updateScale();
            }
        });
        const shrink_button = view.querySelector("#shrink");
        shrink_button.addEventListener('click', (e) => {
            if (this.currentScale > 0.1) {
                this.currentScale -= 0.1;
                this.updateScale();
            }
        });

        if (__SDK_ENABLED__) {
            const formForId = (formId: string): DigitalForm => {
                const forms = this.digitalFormViewController.digitalForms;
                for (let i = 0, l = forms.length; i < l; i++) {
                    const form = forms[i];
                    if (form.id === formId) {
                        return form;
                    }
                }
                throw new Error(`form not found: ${formId}`);
            };

            const getInput = (formId: string, key: string) => {
                const form = formForId(formId);
                const input = findFieldInput(form, key);
                if (!input) {
                    throw new Error(`input not found: ${key}`);
                }
                return input;
            }

            const getValue = (formId: string, key: string) => {
                try {
                    return getInput(formId, key).value;
                } catch (e) {
                }
                return null;
            };

            const postMessage = (data: any) => {
                parent.postMessage(data, __SDK_REFERER_ORIGIN__);
            };

            const resolve = (id: number, value: any) => {
                postMessage({
                    type: "resolve",
                    id,
                    value
                });
            };

            const extractInputs = (form: DigitalFormDefinition) => {
                const inputs = form.boxes.flatMap(box => {
                    return box.elements
                });
                const filtered = inputs.filter(input => ["number", "text", "radio", "checkbox", "select", "textarea"].includes(input.kind));
                return filtered.map(input => ({
                    id: input.id,
                    kind: input.kind,
                    title: input.title
                }));
            };

            window.addEventListener("message", e => {
                if (e.data.type === "formSetValue") {
                    try {
                        getInput(e.data.formId, e.data.key).value = e.data.value;
                        resolve(e.data.id, e.data.value);
                    } catch (e) {
                        resolve(e.data.id, null);
                    }
                } else if (e.data.type === "getRawValue") {
                    resolve(e.data.id, getValue(e.data.formId, e.data.key));
                } else if (e.data.type === "getFormData") {
                    resolve(e.data.id, formForId(e.data.formId).data);
                } else if (e.data.type === "setFormData") {
                    try {
                        formForId(e.data.formId).data = e.data.data;
                        resolve(e.data.id, e.data.data);
                    } catch (e) {
                        resolve(e.data.id, null);
                    }
                }
            });

            // const cache = {};
            this.bindInterceptor = {
                update: (src: BindDataSource, manager: DataBindManager) => {
                    // const key = src.key;
                    // const value = src.value;
                    // if (cache[key] === value) return;
                    // console.log("update", src.);
                    // parent.postMessage({
                    //     type: "formValueChange",
                    //     key,
                    //     value
                    // }, __SDK_REFERER_ORIGIN__);
                    // cache[key] = value;
                }
            }

            postMessage({
                type: "loadForms",
                forms: formData.forms.map(form => ({
                    id: form.id,
                    title: form.title,
                    inputs: extractInputs(form)
                })),
                roleMap: formData.roleMap
            });

            onSavePDF = () => {
                postMessage({
                    type: "savePDF"
                });
            };

            onSaveQRCode = () => {
                postMessage({
                    type: "saveQRCode"
                });
            };
        }
    }

    updateScale() {
        this.paperFormFrames[this.currentFormIndex].contentWindow.document.body.style.transform = "scale( " + this.currentScale + " )";
    }

    async waitForIframePromise(iframe: HTMLIFrameElement): Promise<void> {
        return new Promise((resolve, reject) => {
            const fn = () => {
                iframe.removeEventListener("load", fn);
                resolve();
            };
            iframe.addEventListener("load", fn);
        });
    }

    waitForIframe(iframe, callback) {
        const fn = () => {
            iframe.removeEventListener("load", fn);
            callback(iframe);
        };
        iframe.addEventListener("load", fn);
    }

    public rebind(paperFormFrame?, digitalForm?: DigitalForm) {
        const iframe = paperFormFrame || this.paperFormFrames[this.currentFormIndex];
        digitalForm = digitalForm || this.digitalFormViewController.digitalForms[this.currentFormIndex];
        this.bind(this.binder, iframe, digitalForm);
    }

    private bind(binder: DataBindManager, iframe: HTMLIFrameElement, digitalForm: DigitalForm) {
        const keys = getFieldInputKeys(digitalForm);
        keys.forEach(key => binder.registerSource(new DigitalFormDataSource(digitalForm, key)));
        const errors = [];
        const spans = iframe.contentWindow.document.querySelectorAll<HTMLElement>("[data-bind]");
        for (let i = 0, l = spans.length; i < l; i++) {
            const dst = spans[i];
            const bind = dst.getAttribute('data-bind');
            if (bind) {
                try {
                    binder.registerDestination({
                        key: bind,
                        storage: new HTMLElementBindDataStorage(dst)
                    });
                    if (this.bindInterceptor) {
                        binder.registerDestination({
                            key: bind,
                            storage: this.bindInterceptor
                        });
                    }
                } catch (e) {
                    errors.push("data-id not found: " + bind);
                }
            }
        }
        if (errors.length > 0) console.error('bind error', errors);
    }

    public rebindAll() {
        const iframe = this.paperFormFrames[this.currentFormIndex];
        const digitalForm = this.digitalFormViewController.digitalForms[this.currentFormIndex];
        this.bindFields(iframe, digitalForm);
    }

    private createBinder() {
        return new DataBindManager(__GOOGLE_MAP_API_KEY__);
    }

    bindFields(paperFormFrame, digitalForm) {
        this.waitForIframe(paperFormFrame, () => {
            if (this.binder) {
                this.binder.clearAllSources();
                this.binder.clearAllDestinations();
            }
            this.binder = this.createBinder();
            this.bind(this.binder, paperFormFrame, digitalForm);
        });
    }

    showReadQRDialog(message?: string) {
        Notifier.confirm2(message || "QRコードから復元しますか？", readQR => {
            if (readQR) {
                this.readQRCode();
            }
        });
    }

    decodeQRCode(result: QRResult) {
        const formData = this.formData;
        const forms = this.digitalFormViewController.digitalForms;
        const data = ((raw) => {
            if (raw.charAt(0) === "{") {
                return raw;
            }
            return pako.inflate(raw, {to: "string"});
        })(result.payload);
        const payload = JSON.parse(data);
        for (let i = 0, l = formData.forms.length; i < l; i++) {
            if (formData.forms[i].title === payload.title) {
                this.changeForm(i);
                forms[i].data = payload.data;
                return true;
            }
        }
        return false;
    }

    async readQRCode() {
        const qrreader = new QRCodeReader();
        try {
            const result = await (() => {
                // if (confirm("画像から復元しますか？")) {
                //     return qrreader.readFromImage();
                // }
                return qrreader.startScanning();
            })();
            console.log(result);
            if (result) {
                if (this.decodeQRCode(result)) {
                    this.showReadQRDialog("読み込みが完了しました。引き続き読み込みますか？");
                }
            }
        } catch (e) {
            console.log(e);
        }
        return null;
    }

    private async loadHTML(url: string): Promise<HTMLIFrameElement> {
        return new Promise(resolve => {
            const iframe = document.createElement("iframe");
            const callback = () => {
                resolve(iframe);
                iframe.removeEventListener("load", callback);
            };
            iframe.addEventListener("load", callback);
            iframe.src = url;
            document.body.appendChild(iframe);
        });
    }

    async savePage(mode: string, sources: PageDefinition[], needsPreview: boolean = true) {
        const pages = [];
        let numPages = sources.length;
        this.pdf = null;
        this.pdfCanceled = false;
        this.pdfPreviewWindow.showProgress();

        try {
            for (let i = 0; i < numPages && !this.pdfCanceled; i++) {
                this.pdfPreviewWindow.setProgress(pages.length, numPages);
                const source = sources[i];
                if (source.kind === "form") {
                    const iframe = await this.cloneFormIFreame(source.index);
                    pages.push(await this.iframe2canvas(iframe));
                } else if (source.kind === "dynamic") {
                    const frame = await source.generate();
                    pages.push(await this.iframe2canvas(frame));
                } else if (source.kind === "html") {
                    const frame = await this.loadHTML(source.url);
                    pages.push(await this.iframe2canvas(frame));
                } else if (source.kind === "image") {
                    pages.push(await new Promise((resolve, reject) => {
                        const image = new Image();
                        image.onload = () => {
                            image.onload = null;
                            image.width > image.height ? image.setAttribute("style","width:85vw;height:auto;") : image.setAttribute("style","width:60vw;height:auto;");
                            resolve(image);
                        };
                        image.onerror = (e) => {
                            reject(e);
                        };
                        image.src = source.url;
                    }));
                }
            }

            // make PDF
            const doc = new jsPDF({
                orientation: "portrait",
                unit: "in",
                format: "a4",
            });

            doc.setProperties({
                title: this.formData.pdfMetaTitle || PDF_META_TITLE,
                creator: __BUILD_ID__,
            });

            pages.forEach((page, i) => {
                if (page.width <= page.height) {
                    doc.addImage(page, "JPEG", 0, 0, 21.0 / 2.54, 29.7 / 2.54);
                } else {
                    // rotate 90deg
                    doc.addImage(page, "JPEG", 0, -21.0 / 2.54, 29.7 / 2.54, 21.0 / 2.54, null, null, 270);
                }
                if (i !== pages.length - 1) {
                    doc.addPage();
                }
            });

            this.pdf = doc;

            if (needsPreview) {
                pages.forEach((page) => {
                    this.pdfPreviewWindow.appendPreview(page);
                });
                this.pdfPreviewWindow.showPreview(mode);
            } else {
                this.pdfPreviewWindow.hide();
                if (isiOS) {
                    const result = this.pdf.output("blob", { filename: this.getPDFFilename() });
                    window.open(URL.createObjectURL(result));
                } else {
                    this.pdf.save(this.getPDFFilename());
                }
            }
        } catch (e) {
            console.log(e);
            alert("プレビューの作成中に問題が発生しました。もう一度実行してください。 (" + e + ")");
            this.pdfPreviewWindow.hide();
        }
    }

    // PDF
    savePDF(needsPreview: boolean = true, indices: SerializedPDFPreviewIndexRepresentation = null) {
        const sourceIndices = this.parsePDFPreviewIndices(indices);
        if (sourceIndices.length === 0) {
            alert("無効なフォーム番号が指定されました。 (" + indices + ")");
            return;
        }
        console.log(sourceIndices);
        const sources = sourceIndices.flatMap(index => {
            const forms:PageDefinition[]  = [
                { kind: "form" as const, index }
            ];
            const extraPages = this.formData.forms[index.form - 1].rendering.extraPages;
            if (extraPages) {
                forms.push(...extraPages);
            }
            return forms;
        });
        const pages = sources.concat(this.extraPages);
        const output = this.isQREnabled ? pages.concat(this.buildQRPageDefinition(sourceIndices)) : pages;
        this.savePage("PDF", output, needsPreview);
    }

    buildQRPageDefinition(sourceIndices: PDFPreviewIndexSet[]): DynamicPageDefinition[] {
        const sources: DynamicPageDefinition[] = [];
        for (let i = 0, l = sourceIndices.length; i < l; i++) {
            const index = sourceIndices[i];
            const title = this.formData.forms[index.form - 1].title;
            const qr_template = this.formData.forms[index.form - 1].qr_template;
            const form = this.digitalFormViewController.digitalForms[index.form - 1];
            sources.push({
                kind: "dynamic",
                generate: () => {
                    return new Promise((resolve, reject) => {
                        const iframe = document.createElement("iframe");
                        // iframe.style.display = "none";
                        iframe.addEventListener("load", async () => {
                            // iframe.contentDocument.querySelector("[data-name='title']").textContent = "千葉市" + title + " 必要事項";
                            //const payload = JSON.stringify(form.data);
                            const payload = JSON.stringify({
                                title,
                                data: form.data
                            });
                            const qrcodeArea = iframe.contentDocument.querySelector<HTMLImageElement>("[data-name='qrcode']");
                            if (qrcodeArea) {
                                const compressed = pako.deflate(payload, { to: "string" });
                                qrcodeArea.src = await QRCode.toDataURL(compressed, {
                                    errorCorrectionLevel: "M"
                                });
                            }
                            let date = new Date();
                            const today = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
                            const createdDateArea = iframe.contentDocument.querySelector("[data-name='pdf_created_date']");
                            if (createdDateArea) {
                                createdDateArea.textContent = `${today}作成`;
                                resolve(iframe);
                            }
                        });
                        iframe.src = qr_template;
                        document.body.appendChild(iframe);
                    });
                }
            });

        }
        return sources;
    }

    async saveQRCode(needsPreview: boolean = true, indices: SerializedPDFPreviewIndexRepresentation = null) {
        const sourceIndices = this.parsePDFPreviewIndices(indices);
        if (sourceIndices.length === 0) {
            alert("無効なフォーム番号が指定されました。 (" + indices + ")");
            return;
        }
        this.savePage("QR", this.buildQRPageDefinition(sourceIndices), needsPreview);
    }

    parsePDFPreviewIndices(indices?: SerializedPDFPreviewIndexRepresentation): PDFPreviewIndexSet[] {
        if (indices === null || indices === undefined) {
            return this.paperFormFrames.reduce((result, elm, i) => {
                const form = i + 1;
                const num = +elm.getAttribute("data-num-pages");
                for (let page = 1; page <= num; ++page) {
                    result.push(new PDFPreviewIndexSet(form, page));
                }
                return result;
            }, []);
        }
        if (Array.isArray(indices)) {
            return indices.reduce((result, index) => {
                if (index !== null && index !== undefined) {
                    const tmp = this.parsePDFPreviewIndices(index);
                    result = result.concat(tmp);
                }
                return result;
            }, []);
        }
        const form = +indices;
        if (!isNaN(form)) {
            if (form >= 1 && form <= this.paperFormFrames.length) {
                const elm = this.paperFormFrames[form - 1];
                const result = [];
                const num = +elm.getAttribute("data-num-pages");
                for (let page = 1; page <= num; ++page) {
                    result.push(new PDFPreviewIndexSet(form, page));
                }
                return result;
            }
            return [];
        }
        if (typeof (indices) === "string") {
            const pair = indices.split("-");
            if (pair.length === 2) {
                const form = +pair[0];
                const page = +pair[1];
                if (!isNaN(page) && !isNaN(form) && form >= 1 && form <= this.paperFormFrames.length) {
                    const elm = this.paperFormFrames[form - 1];
                    const num = +elm.getAttribute("data-num-pages");
                    if (page >= 1 && page <= num) {
                        return [new PDFPreviewIndexSet(form, page)];
                    }
                }
            }
            return [];
        }
        return [];
    }

    getPDFFilename() {
        const pdfFileName = this.formData.pdfFileName || PDF_FILE_NAME;
        return `${pdfFileName}${DEBUG ? '-' + Date.now() : ''}.pdf`;
    }

    getPDFPreviewContentHeight() {
        return this.pdfPreviewWindow.getContentHeight();
    }

    isHorizontalContent(iframe: HTMLIFrameElement) {
        return iframe.contentWindow.document.querySelector('body > .horizontal') !== null;
    }

    private async cloneFormIFreame(index: PDFPreviewIndexSet): Promise<HTMLIFrameElement> {
        const width = 1240;
        const height = 1754;
        const iframe = this.paperFormFrames[index.form - 1];
        return new Promise((resolve, reject) => {
            const form = index.form;
            const page = index.page;
            const container = this.view.querySelector(".hidden");
            const cloned = <HTMLIFrameElement>iframe.cloneNode(true);
            container.appendChild(cloned);
            cloned.style.display = "block";
            cloned.style.width = width + "px",
                cloned.style.height = height + "px";
            cloned.setAttribute("src", iframe.src);

            this.waitForIframe(cloned, () => {
                // apply input
                cloned.contentWindow.document.body.innerHTML = iframe.contentWindow.document.body.innerHTML;
                const binder = this.createBinder();
                this.bind(binder, cloned, this.digitalFormViewController.digitalForms[form - 1]);
                this.changePage(page, cloned);
                resolve(cloned);
            });
        });
    }

    async iframe2canvas(cloned: HTMLIFrameElement) {
        const width = 1240;
        const height = 1754;

        const horizontal = this.isHorizontalContent(cloned);
        const canvas = await html2canvas(cloned.contentWindow.document.body, {
            width: horizontal ? height : width,
            height: horizontal ? width : height,
            scale: 1,
            backgroundColor: "#ffffff",
            useCORS: true,
            onclone: isiPhone ? this.onIFrameClone : null,
            logging: DEBUG,
        });
        if (cloned.parentNode) {
            cloned.parentNode.removeChild(cloned);
        }
        canvas.style.width = horizontal ? "85vw" : "60vw";
        canvas.style.height = null;
        return canvas;
    }

    onIFrameClone(doc) {
        Array.prototype.slice.call(doc.querySelectorAll("[data-fs]")).forEach((elm) => {
            for (let i = 5; i <= 18; ++i) {
                elm.classList.remove("pt" + i);
            }
            var fontSize = elm.getAttribute("data-fs");
            if (fontSize.startsWith("pt")) {
                elm.classList.add(fontSize);
            } else {
                const ppi = 150;
                const zoom = 0.87;
                var point = +fontSize;
                if (!isNaN(point)) {
                    elm.style.fontSize = `calc(${point} / 72 * ${ppi} * ${zoom} * 0.08vw)`;
                }
            }
        });
    }

    changeForm(formIndex) {
        if (this.currentFormIndex === formIndex) {
            this.rebindAll();
            return;
        }
        if (this.currentFormIndex !== undefined) {
            const lastFrame = this.paperFormFrames[this.currentFormIndex];
            lastFrame.style.display = "none";
            this.digitalFormViewController.selectedIndex = -1;
        }
        this.currentFormIndex = formIndex;
        const iframe = this.paperFormFrames[this.currentFormIndex];
        iframe.style.display = "block";
        const digitalForm = this.digitalFormViewController.digitalForms[this.currentFormIndex];
        this.digitalFormViewController.selectedIndex = this.currentFormIndex;
        this.bindFields(iframe, digitalForm);
        this.changePageSafe(1);
        this.updateButtonState();
    }

    changePageSafe(pageNumber: number) {
        const paperFormFrame = this.paperFormFrames[this.currentFormIndex];
        const formIndex = this.currentFormIndex;
        const fn = () => {
            paperFormFrame.removeEventListener("load", fn);
            if (this.currentFormIndex === formIndex) {
                this.changePage(pageNumber)
            }
        };
        paperFormFrame.addEventListener("load", fn);
    }

    changePage(pageNumber: number, iframe: HTMLIFrameElement = null) {
        iframe = iframe || this.paperFormFrames[this.currentFormIndex];
        iframe.setAttribute("data-page", String(pageNumber));
        for (let i = 1; i <= 3; ++i) {
            const hidden = i !== +pageNumber;
            const elms = iframe.contentWindow.document.getElementsByClassName("only-" + i);
            Array.prototype.forEach.call(elms, (elm) => {
                if (pageNumber === -1) {
                    elm.removeAttribute("data-display");
                    elm.style.display = null;
                    elm.removeAttribute("style");
                } else {
                    if (!elm.getAttribute("data-display")) {
                        var style = document.defaultView.getComputedStyle(elm);
                        elm.setAttribute("data-display", style.display);
                    }
                    elm.style.display = hidden ? "none" : elm.getAttribute("data-display");
                }
            });
        }
        //決め打ちにしてしまう
        let iframe_main_class = iframe.contentWindow.document.querySelector('main').classList;
        let iframe_scroll_width = 1240;
        //tsの中だからes6使えるのかもしれんけどよくわからんのでcontains使わないでこんな感じに
        if (iframe_main_class[0] === 'horizontal') {
            iframe_scroll_width = 1754;
        }
        iframe.contentWindow.document.querySelector("main").setAttribute("data-page", String(pageNumber));
        // あえて一瞬待つことで上の変更を反映させる
        setTimeout(() => {
            this.currentScale = this.mainWindow.offsetLeft / iframe_scroll_width;
            this.updateScale();
            const tabButton = document.querySelector('.generated>nav button[selected]');
            if (tabButton) {
                tabButton.scrollIntoView({ block: "end", inline: "end" });
            }
        });
    }

    updateButtonState() {
        this.nextFormButton.disabled = this.isBinding || this.currentFormIndex === this.paperFormFrames.length - 1;
        this.prevFormButton.disabled = this.isBinding || this.currentFormIndex === 0;
        const iframe = this.paperFormFrames[this.currentFormIndex];
        let num = +iframe.getAttribute("data-num-pages");
        this.changePageButton.disabled = this.isBinding || num <= 1;
        for (let j = 0; j < this.navButtons.length; ++j) {
            const button = this.navButtons[j];
            if (j === this.currentFormIndex) {
                button.setAttribute("selected", "");
                button.setAttribute("aria-selected", "true");
            } else {
                button.removeAttribute("selected");
                button.setAttribute("aria-selected", "false");
            }
        }
    }
}

class DigitalFormViewController {
    public readonly view: HTMLElement;
    public readonly digitalForms: DigitalForm[] = [];
    private titles: string[] = [];
    private titleElement: HTMLElement;
    private container: HTMLElement;
    private index: number;

    constructor(formData: DigitalApplicationDefinition, view: HTMLElement, data) {
        this.view = view;
        this.titleElement = this.view.querySelector("[data-role=title]") as HTMLElement;
        this.container = view.getElementsByTagName("MAIN")[0] as HTMLElement;
        const map: { [keys: string]: DigitalForm } = {};
        const definitionMap: { [keys: string]: DigitalFormDefinition } = {};
        for (let i = 0, l = formData.forms.length; i < l; i++) {
            const formDefinition = formData.forms[i];
            const digitalForm = buildDigitalForm(
                formDefinition,
                new DOMTemplate(document.getElementById("template"))
            );
            digitalForm.data = data;
            map[formDefinition.id] = digitalForm;
            definitionMap[formDefinition.id] = formDefinition;
            this.digitalForms.push(digitalForm);
            this.titles.push(formDefinition.title);
        }
        if (formData.extraSetup) {
            formData.extraSetup(map, definitionMap);
        }
        this.index = -1;
    }
    //
    // decodeQueryData(){
    //     const queryString = location.search;
    //     const dataString = /data=([^&]*)$/.exec(queryString);
    //     if(dataString){
    //         const jsonString = decodeURIComponent(dataString[1]);
    //         try{
    //             return JSON.parse(jsonString);
    //         }catch(e){
    //         }
    //     }
    //     return {};
    // }

    get selectedIndex(): number {
        return this.index;
    }

    set selectedIndex(value: number) {
        const children = this.container.children;
        for (let i = 0; i < children.length; ++i) {
            this.container.removeChild(children[i]);
        }
        if (value >= 0 && value < this.digitalForms.length) {
            this.container.appendChild(this.digitalForms[value].element);
            this.titleElement.textContent = this.titles[value];
            this.index = value;
        } else {
            this.index = -1;
        }
    }
}

class PDFPreviewIndexSet {
    public form: number;
    public page: number;

    constructor(form: number, page: number) {
        this.form = form;
        this.page = page;
    }
}

class PDFPreviewWindow {
    private view: HTMLElement;
    private container: HTMLElement;
    private progressView: Element;
    private progressBar: Element;
    private previewView: Element;
    private previewFrame: HTMLIFrameElement;
    private readonly saveButton: HTMLButtonElement;
    public onSave: Function;
    public onCancel: Function;
    public onClose: Function;

    constructor(view, container) {
        this.view = view;
        this.container = container;
        this.progressView = this.view.getElementsByClassName("pdf-progress")[0];
        this.progressBar = this.progressView.getElementsByClassName("pdf-progress-bar")[0];
        this.previewView = this.view.getElementsByClassName("pdf-preview")[0];
        this.previewFrame = this.previewView.getElementsByTagName("IFRAME")[0] as HTMLIFrameElement;

        if (isiOS) {
            this.view.addEventListener("touchmove", this.preventScroll, false);
        }

        const cancelButton = this.view.getElementsByClassName("pdf-progress-cancel")[0];
        cancelButton.addEventListener("click", (e) => {
            if (this.onCancel) {
                this.onCancel();
            }
            e.preventDefault();
            return false;
        });

        const saveButton = this.view.getElementsByClassName("pdf-preview-save")[0] as HTMLButtonElement;
        saveButton.addEventListener("click", () => {
            if (this.onSave) { this.onSave(); }
        });
        this.saveButton = saveButton;
        const closeButton = this.view.getElementsByClassName("pdf-preview-close")[0];
        closeButton.addEventListener("click", () => {
            this.hide();
            if (this.onClose) { this.onClose(); }
        });

        this.saveStyle(this.progressView);
        this.saveStyle(this.previewView);
    }

    getContentHeight(): number {
        const doc = this.previewFrame.contentWindow.document;
        const elm = doc.getElementById("container");
        if (!elm) { return 0; }
        const style = doc.defaultView.getComputedStyle(elm);
        const height = +(style.height.replace("px", ""));
        return isNaN(height) ? 0 : height;
    }

    isHidden(): boolean {
        return this.view.parentNode === null;
    }

    show() {
        if (this.isHidden()) {
            this.container.appendChild(this.view);
        }
    }

    hide() {
        if (!this.isHidden()) {
            this.container.removeChild(this.view);
        }
        this.hideProgress();
        this.hidePreview();
        const images = Array.prototype.slice.call(this.previewView.getElementsByClassName("pdf-preview-item"));
        images.forEach((elm) => {
            elm.parentNode.removeChild(elm);
        });
    }

    showProgress() {
        this.show();
        this.restoreStyle(this.progressView);
        this.previewView.style.display = "none";
    }

    hideProgress() {
        this.progressView.style.display = "none";
    }

    showPreview(mode: string) {
        this.show();
        this.saveButton.setAttribute("class", `pdf-preview-save mode-${mode}`);
        this.progressView.style.display = "none";
        this.restoreStyle(this.previewView);
        this.view.querySelector('.pdf-preview-menu').classList.remove('hidden');
    }

    hidePreview() {
        this.previewView.style.display = "none";
        this.view.querySelector('.pdf-preview-menu').classList.add('hidden');
    }

    saveStyle(elm) {
        var style = document.defaultView.getComputedStyle(elm);
        elm.setAttribute("data-display", style.display);
    }

    restoreStyle(elm) {
        const display = elm.getAttribute("data-display");
        elm.style.display = display;
    }

    setProgress(currentValue: number, maxValue: number) {
        const progress = Math.max(0, Math.min(currentValue / maxValue * 100, 100));
        this.progressBar.style.width = progress + "%";
    }

    appendPreview(content: HTMLElement) {
        const template = document.getElementById("template");
        const item = template.getElementsByClassName("pdf-preview-item")[0].cloneNode(true);
        item.appendChild(content);
        const container = this.previewFrame.contentWindow.document.getElementById("container");
        container.appendChild(item);
    }

    private preventScroll(e) {
        e.preventDefault();
    }
}

export class Notifier {

    public readonly alertbox: HTMLElement;
    public readonly lightbox: HTMLElement;
    public readonly button: HTMLElement;
    public readonly cancelButton: HTMLElement;
    private callback?: (boolean) => void;

    private constructor(message: string, template: HTMLElement, callback: (boolean) => void = null) {
        this.alertbox = template.cloneNode(true);
        this.alertbox.addEventListener('touchmove', this.preventScroll, false);

        this.callback = callback;

        const messagebox = this.alertbox.querySelector('p');
        messagebox.textContent = message;

        this.lightbox = document.createElement('div');
        this.lightbox.setAttribute('class', 'lightbox');
        this.lightbox.setAttribute('style', `height:${document.body.scrollHeight + 'px'}`);
        this.lightbox.addEventListener('touchmove', this.preventScroll, false);

        this.button = this.alertbox.querySelector('.okButton');
        this.button.addEventListener('click', (e) => {
            const callback = this.callback;
            this.callback = null;
            this.close();
            if (callback) { callback(true); }
        });

        const cancelButton = this.alertbox.querySelector('.cancelButton');
        if (cancelButton) {
            cancelButton.addEventListener('click', e => {
                const callback = this.callback;
                this.callback = null;
                this.close();
                if (callback) { callback(false); }
            });
        }

        document.body.insertBefore(this.lightbox, document.body.firstChild);
        document.body.appendChild(this.alertbox);
    }

    static show(message: string, callback: (boolean) => void = null): Notifier {
        const template = document.getElementById("template").querySelector(".notification.alert");
        return new Notifier(message, template, callback);
    }

    static confirm(message: string, callback: (boolean) => void = null): Notifier {
        const template = document.getElementById("template").querySelector(".notification.confirm.ending");
        return new Notifier(message, template, callback);
    }

    static confirm2(message: string, callback: (boolean) => void = null): Notifier {
        const template = document.getElementById("template").querySelector(".notification.confirm.normal");
        return new Notifier(message, template, callback);
    }

    static confirmWithLabel(message: string, label: string, callback: (result: boolean) => void): Notifier {
        const view = <HTMLElement>document.getElementById("template").querySelector(".notification.confirm").cloneNode(true);
        view.querySelector(".okButton").textContent = label;
        return new Notifier(message, view, callback);
    }

    preventScroll(e) {
        e.preventDefault();
    }

    close() {
        this.alertbox.parentNode.removeChild(this.alertbox);
        this.lightbox.removeEventListener('touchmove', this.preventScroll, false);
        this.lightbox.parentNode.removeChild(this.lightbox);
        if (this.callback) {
            this.callback(false);
            this.callback = null;
        }
    }
}


/*Bowser*/
const browser = Bowser.getParser(window.navigator.userAgent);
//console.log(browser.parse());
const isValidBrowser = browser.satisfies({
    windows: {
        "internet explorer": ">10",
        "microsoft edge": ">16",
        chrome: ">72"
    },
    macos: {
        safari: ">11",
        chrome: ">72"
    },
    mobile: {
        safari: '>11',
        chrome: ">72"
    },
    tablet: {
        safari: '>11',
        chrome: ">72"
    }
});
// const isiOS = browser.satisfies({
//     mobile: {
//         safari: '>9'
//     },
//     tablet: {
//         safari: '>9'
//     }
// });

const osInfo = browser.parseOS();
const browserInfo = browser.getBrowser();
const isiPad13 = browserInfo.name === "Safari" && typeof document.ontouchstart !== 'undefined' && browser.parsePlatform().type !== 'mobile';
const isiOS = osInfo.name === 'iOS' || isiPad13;
// console.log(isValidBrowser);
const isiPhone = isiOS && browser.getPlatform().type === 'mobile';

const systemInfo = osInfo.name + "-" + osInfo.version + " " + browserInfo.name + "-" + browserInfo.version;

const UNSUPPORTED_BROWSER_MESSAGE = "お使いのブラウザは本サービスに対応していない為、正常にご利用できない可能性があります。";
const ARE_YOU_SURE_TO_LEAVE_MESSAGE = "入力されたデータが失われますが続けますか？";
