import { FieldInputElementWrapper } from "./FieldInputElementWrapper";
import { FieldInputElement } from "./Element/FieldInputElement";
import { FieldInputContainer } from "./Element/FieldInputContainer";
import { DOMTemplate } from "../denki";

export class FieldCompositeElement extends FieldInputElementWrapper implements FieldInputContainer {
  public readonly elements: FieldInputElement[] = [];
  public calculateValue?: (element: FieldCompositeElement) => any;

  public constructor(name: string, template: DOMTemplate) {
    super(name, template);
    this.element.classList.add("composite_element");
  }

  calcValue() {
    const calculateValue = this.calculateValue;
    const elementsWrapper = this.elementsWrapper;
    const elements = this.elements;
    if (calculateValue) {
      return calculateValue(this);
    } else if (elementsWrapper.querySelectorAll("input").length === 1) {
      return JSON.stringify(elementsWrapper.querySelector("input").value)
    }
    const v = {};
    for (let i = 0, l = elements.length; i < l; i++) {
      const element = elements[i];
      const id = element.id;
      const value = element.value;
      try {
        v[id] = JSON.parse(value);
      } catch (e) {
      }
      // if (element.definition.kind === "dom") {
      //   const value = element.calcCompositeValue().compositeValue;
      //   for (let j in value) {
      //     v[`${id}-${j}`] = value[j];
      //   }
      // }
    }
    return JSON.stringify(v);
  }

  focus() {
    this.elements[0].focus();
  }

  get validationErrors(): string[] {
    return this.elements.map(
      element => element.validationErrors
    ).reduce((acc, value) => acc.concat(value), []);
  }

  protected updateValidation() {
    const value = this.value;
    const parsed = value ? JSON.parse(value) : "";
    let errors = this.validationErrors;
    this.titleElement.classList.remove("completed");
    if (errors.length === 0) {
      if (this.required || parsed) {
        this.titleElement.classList.add("completed");
      }
    }
  }

  public appendFieldInput(element: FieldInputElement) {
    this.registerInput(element.virtualInput);
    this.elementsWrapper.appendChild(element.element);
    this.elements.push(element);
  }

  public removeFieldInput(element: FieldInputElement) {
    this.unregisterInput(element.virtualInput);
    this.elementsWrapper.removeChild(element.element);
    const index = this.elements.indexOf(element);
    this.elements.splice(index, 1);
  }

  public get numberOfFieldInputs(): number {
    return this.elements.length;
  }

  indexOfFieldInput(id: string) {
    for (let i = 0, l = this.elements.length; i < l; i++) {
      if (this.elements[i].id === id) {
        return i;
      }
    }
    throw new Error(`field element was not found: ${id}`);
  }

  fieldInputAtIndex(index: number): FieldInputElement {
    return this.elements[index];
  }

  updateFieldInput(input: FieldInputElement, id: string) {

  }

  replaceElement(newElement: FieldInputElement, id: string) {
    const index = this.indexOfFieldInput(id);
    const element = this.elements[index];
    this.unregisterInput(element.virtualInput);
    this.registerInput(newElement.virtualInput);
    this.elementsWrapper.replaceChild(newElement.element, element.element);
    this.elements[index] = newElement;
  }

  get disabled() {
    return this._disabled;
  }

  set disabled(disabled: boolean) {
    if (this._disabled === disabled) return;
    this._disabled = disabled;
    this.elements.forEach(e => e.disabled = disabled);
    this.dispatchChangeEvent();
  }

}