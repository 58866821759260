import { FieldInputElement } from "./Element/FieldInputElement";
import { DOMTemplate } from "../denki";
import { FormElement } from "./Element/FormElement";
import { FieldCompositeElement } from "./FieldCompositeElement";
import { FieldInputContainer, findFieldInput } from "./Element/FieldInputContainer";

export class FieldBox extends FormElement implements FieldInputContainer, FieldInputElement {
  focus: () => void;
  public onToggleOpen: (arg0: FieldBox) => void;
  public onUpdateFieldInput: (arg0: FieldBox, newInput: FieldCompositeElement, id: string) => void;
  private toggleButton: HTMLButtonElement;
  private fieldInputs: FieldInputElement[] = [];
  private readonly fieldsElement: HTMLElement;

  constructor(id: string, template: DOMTemplate) {
    super(id, template.get("field-box"));
    const element = this.element;
    this.fieldsElement = <HTMLElement>element.querySelector("[data-role=fields]");
    this._disabled = false;

    this.toggleButton = element.querySelector(".toggle");
    element.querySelector(".row").addEventListener('click', (e) => {
      this.toggleClose();
    });
  }

  public indexOfFieldInput(id: string): number {
    for (let i = 0, l = this.fieldInputs.length; i < l; i++) {
      const fieldInput = this.fieldInputs[i];
      if (fieldInput.id === id) {
        return i;
      }
    }
    throw new Error(`field input was not found: ${id}`);
  }

  get numberOfFieldInputs(): number {
    return this.fieldInputs.length;
  }

  public fieldInputAtIndex(index: number): FieldInputElement {
    return this.fieldInputs[index];
  }

  public appendFieldInput(input: FieldInputElement, decorator: HTMLElement = null) {
    this.fieldInputs.push(input);
    if (decorator) {
      decorator.appendChild(input.element);
      this.fieldsElement.appendChild(decorator);
    } else {
      this.fieldsElement.appendChild(input.element);
    }
  }

  public removeFieldInput(input: FieldInputElement) {
    const index = this.indexOfFieldInput(input.id);
    if (index === -1) throw new Error(`field input ${input.id} not found`);
    this.fieldInputs.splice(index, 1);
    this.fieldsElement.removeChild(this.fieldsElement.children.item(index));
  }

  // TODO: FieldCompositeElementに限らない
  public updateFieldInput(newInput: FieldCompositeElement, id: string) {
    const fieldInput = <FieldCompositeElement>findFieldInput(this, id);
    const oldNum = fieldInput.elements.length;
    const newNum = newInput.elements.length;
    if (oldNum > newNum) {
      while (fieldInput.elements.length > newNum) {
        fieldInput.removeFieldInput(fieldInput.elements[fieldInput.elements.length - 1]);
      }
    } else if (oldNum < newNum) {
      for (let i = oldNum; i < newNum; i++) {
        const element = newInput.elements[oldNum];
        newInput.removeFieldInput(element);
        fieldInput.appendFieldInput(element);
      }
    }
    fieldInput.update();
    if (this.onUpdateFieldInput) {
      this.onUpdateFieldInput(this, newInput, id);
    }
  }

  public toggleClose() {
    if (this.disabled && this.isClosed) {
      return;
    }
    this.toggleButton.classList.toggle("opened");
    this.element.classList.toggle("closed");
    if (this.onToggleOpen) {
      this.onToggleOpen(this);
    }
  }

  public get isOpen() {
    return !this.isClosed;
  }

  public get isClosed() {
    return this.element.classList.contains("closed");
  }

  public open() {
    if (this.isClosed) {
      this.toggleClose();
    }
  }

  public close() {
    if (this.isOpen) {
      this.toggleClose();
    }
  }

  public get hidden() {
    return this.element.style.display === "none";
  }

  public set hidden(hidden: boolean) {
    this.element.style.display = hidden ? "none" : "";
  }

  public get disabled() {
    return this._disabled;
  }

  public set disabled(disabled: boolean) {
    if (this._disabled === disabled) return;
    this._disabled = disabled;
    if (this._disabled) {
      this.close();
    }
    this.element.classList.toggle('disabled');
    this.fieldInputs.forEach(e => e.disabled = disabled);
  }

  public get isRequired() {
    return false;
  }

  public set isRequired(required: boolean) {

  }
}
