export interface BindDataSource {
    key: string;
    value: string
    focus: (HTMLElement) => void,
    addChangeListener: (func: () => void) => void
    removeChangeListener: (func: () => void) => void
}

export interface BindDataStorage {
    update: (src: BindDataSource, manager: DataBindManager) => void;
    onRegister?: (manager: DataBindManager, key: string) => void;
    onUnregister?: (manager: DataBindManager, key: string) => void;
}

const canvas = document.createElement("canvas");
const context = canvas.getContext("2d");

export class HTMLElementBindDataStorage implements BindDataStorage {
    private element: HTMLElement;
    private callback: () => void;

    constructor(element: HTMLElement) {
        this.element = element;
    }

    onRegister(manager: DataBindManager, key: string) {
        this.callback = () => {
            manager.onDestinationClick({
                key,
                storage: this
            });
        }
        this.element.addEventListener("click", this.callback);
    }

    onUnregister(manager: DataBindManager, key: string) {
        this.element.removeEventListener("click", this.callback);
        this.callback = undefined;
    }

    update(src: BindDataSource, manager: DataBindManager) {
        const dst = this.element;
        const value = src.value;
        const dataType = dst.getAttribute('data-type');
        if (dataType === 'radio') {
            const targetClass = dst.getAttribute('data-target-class');
            if (value && dst.getAttribute('data-target-value') === JSON.parse(value)) {
                dst.classList.add(targetClass);
            } else {
                dst.classList.remove(targetClass);
            }
        } else {
            const referSrc = manager.resolveReference(src);
            if (referSrc !== src) {
                const callback = () => {
                    if (src.value === value) {
                        this.update(referSrc, manager);
                    } else {
                        referSrc.removeChangeListener(callback)
                    }
                };
                referSrc.addChangeListener(callback);
            }

            let parsed = JSON.parse(referSrc.value);
            const modifier = dst.getAttribute("data-modifier");
            if (modifier === "gengo") {
                parsed = parsed === "1" ? "元" : parsed;
            }
            if (modifier === "no-space") {
                parsed = parsed.replace(/[ 　\t]/, "");
            }
            if (dataType === 'map') {
                const locationInfo = parsed;
                dst.innerHTML = "";
                if (parsed) {
                    dst.classList.remove("handwriting");
                    const image = <HTMLImageElement>document.createElement("img");
                    const query = `center=${locationInfo.center.lat},${locationInfo.center.lng}`
                        + `&zoom=${locationInfo.zoom}&size=640x400&scale=2&key=${manager.mapApiKey}`
                        + `&style=saturation:-100&style=feature:poi|visibility:on`
                        + `&style=feature:transit|visibility:off`
                        + `&style=feature:administrative|visibility:on`
                        + `&style=feature:road|visibility:simplified`
                        + `&markers=size:mid|color:black||${locationInfo.marker.lat},${locationInfo.marker.lng}`;
                    const url = `https://maps.googleapis.com/maps/api/staticmap?${query}`;
                    image.width = 640;
                    image.height = 400;
                    image.setAttribute('src', url);
                    dst.appendChild(image);
                } else {
                    dst.classList.add("handwriting");
                }
            } else if (dataType === 'checkbox') {
                const targetClass = dst.getAttribute('data-target-class');
                const target = dst.getAttribute('data-target-value');
                const operator = dst.getAttribute('data-target-operator');
                if (parsed !== null && parsed.includes(target) === (operator !== "not")) {
                    dst.classList.add(targetClass);
                } else {
                    dst.classList.remove(targetClass);
                }
            } else if (dataType === "char") {
                let str = String(parsed || "");
                if (dst.getAttribute("data-modifier") === "gengo") {
                    str = str === "1" ? "元" : str;
                }
                const offset = parseInt(dst.getAttribute("data-offset"));
                const align = dst.getAttribute("data-digit-align");
                if (align === "right") {
                    const length = str.length;
                    if (length <= offset) {
                        dst.textContent = "";
                    } else {
                        dst.textContent = str.substr(length - offset - 1, 1);
                    }
                } else if (!align || align === "left") {
                    const length = str.length;
                    if (length <= offset) {
                        dst.textContent = "";
                    } else {
                        dst.textContent = str.substr(offset, 1);
                    }
                }
            } else {
                if (parsed !== undefined) {
                    dst.textContent = parsed;
                } else {
                    dst.textContent = "";
                }
            }
            if (dst.textContent && dst.offsetWidth > 0 && dst.getAttribute("data-auto-font-scale") !== "false") {
                const textContent = dst.textContent;
                dst.style.fontSize = "";
                const computedStyle = getComputedStyle(dst);
                let fontSize = parseInt(computedStyle.getPropertyValue("font-size").replace("px", ""));
                const fontFamily = computedStyle.getPropertyValue("font-family");
                try {
                    do {
                        dst.style.fontSize = `${fontSize}px`;
                        context.font = `${fontSize}px ${fontFamily}`;
                        fontSize--;
                    } while (fontSize > 1 && context.measureText(textContent).width > dst.offsetWidth);
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }
}

export interface BindDataDestination {
    key: string;
    storage: BindDataStorage;
}

export class DataBindManager {
    public readonly mapApiKey: string;
    private sourceMapper: { [key: string]: { src: BindDataSource, callback: () => void } } = {};
    private destinations: BindDataDestination[] = [];

    constructor(mapApiKey: string = null) {
        this.mapApiKey = mapApiKey;
    }

    onSourceUpdate(src: BindDataSource) {
       this.destinations.filter(dst => dst.key === src.key).forEach(dst => {
            this.updateDestination(src, dst);
       });
    }

    onDestinationClick(dst: BindDataDestination) {
        const source = this.sourceMapper[dst.key];
        if (source) {
            source.src.focus(dst.storage);
        }
    }

    clearAllSources() {
        Object.keys(this.sourceMapper).forEach(key => {
            const source = this.sourceMapper[key];
            source.src.removeChangeListener(source.callback);
        });
        this.sourceMapper = {};
    }

    registerSource(src: BindDataSource) {
        if (!this.sourceMapper[src.key]) {
            const callback = () => {
                this.onSourceUpdate(src);
            };
            this.sourceMapper[src.key] = {
                src,
                callback
            };
            src.addChangeListener(callback);
        }
    }

    clearAllDestinations() {
        this.destinations.forEach(dst => {
            if (dst.storage.onUnregister) {
                dst.storage.onUnregister(this, dst.key);
            }
        });
        this.destinations = [];
    }

    public resolveReference(src: BindDataSource): BindDataSource {
        const v = src.value;
        if (!v) {
            return src;
        }
        const value = JSON.parse(v);
        if (value && typeof value === "string" && value.indexOf("refer:") === 0) {
            const key = value.substring(6);
            const source = this.sourceMapper[key];
            if (source) {
                return source.src;
            }
            return src;
        }
        return src;
    }

    updateDestination(src: BindDataSource, dst: BindDataDestination) {
        dst.storage.update(src, this);
    }

    registerDestination(dst: BindDataDestination) {
        if (dst.storage.onRegister) {
            dst.storage.onRegister(this, dst.key);
        }
        this.destinations.push(dst);
        const source = this.sourceMapper[dst.key];
        if (source) {
            this.updateDestination(source.src, dst);
        }
    }
}
