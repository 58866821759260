export class FormElement<T = string> {
  public id: string;
  public readonly element: HTMLElement;
  public readonly titleElement: HTMLElement;
  public readonly descriptionElement: HTMLElement;
  public readonly virtualInput: HTMLInputElement;
  public readonly errors?: HTMLElement;
  protected _disabled: boolean;

  constructor(id: string, element: HTMLElement) {
    this.id = id;
    this.titleElement = <HTMLElement>element.querySelector("[data-role=title]");
    this.descriptionElement = <HTMLElement>element.querySelector("[data-role=description]");
    this.virtualInput = <HTMLInputElement>element.querySelector("[data-role=virtualInput]");
    this.titleElement.style.display = "none";
    this.descriptionElement.style.display = "none";
    const errors = <HTMLElement>element.querySelector("[data-role=errors]");
    if (errors) {
      errors.style.display = "none";
      this.errors = errors;
    }
    element.setAttribute("id", `field_element-${id}`);
    this.element = element;
  }

  public get title() {
    return this.titleElement.textContent;
  }

  public set title(title: string) {
    if (title) {
      this.titleElement.textContent = title;
      this.titleElement.style.display = "";
    } else {
      this.titleElement.textContent = "";
      this.titleElement.style.display = "none";
    }
  }

  public get description() {
    return this.descriptionElement.textContent;
  }

  public set description(description: string) {
    this.descriptionElement.innerHTML = "";
    if (description) {
      const lines = description.split("\n");
      lines.forEach(line => {
        const p = document.createElement("p");
        p.textContent = line;
        this.descriptionElement.appendChild(p);
      })
      this.descriptionElement.style.display = "";
    } else {
      this.descriptionElement.style.display = "none";
    }
  }

  public get required() {
    return this.titleElement.classList.contains("required");
  }

  public set required(required: boolean) {
    if (required) {
      this.titleElement.classList.add("required");
    } else {
      this.titleElement.classList.remove("required");
    }
  }

  protected updateValidation() {
    const errors = this.validationErrors;
    if (errors && errors.length > 0) {
      this.titleElement.classList.remove("completed");
      if (this.errors) {
        this.errors.textContent = errors[0];
        this.errors.removeAttribute("style");
      }
    } else {
      if (this.errors) {
        this.errors.textContent = "";
        this.errors.style.display = "none";
      }
      if (this.required || this.value) {
        this.titleElement.classList.add("completed");
      }
    }
  }

  protected dispatchChangeEvent() {
    const e = document.createEvent("HTMLEvents");
    e.initEvent("change", false, true);
    this.virtualInput.dispatchEvent(e);
  }

  protected onUpdateValue(value: T) { }

  get value(): T {
    try {
      return (this.hidden || this.disabled) ? null : JSON.parse(this.virtualInput.value);
    } catch (e) {
    }
    return null;
  }

  set value(value: T) {
    this.virtualInput.value = JSON.stringify(value);
    this.updateValidation();
    this.dispatchChangeEvent();
    this.onUpdateValue(value);
  }

  get hidden() {
    return this.element.style.display === "none";
  }

  set hidden(value) {
    if (value === this.hidden) return;
    if (value) {
      this.element.style.display = "none";
    } else {
      this.element.style.display = "";
      this.element.removeAttribute("style");
    }
    this.dispatchChangeEvent();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(disabled: boolean) {
    if (this._disabled === disabled) return;
    this._disabled = disabled;
    this.dispatchChangeEvent();
  }

  get validationErrors(): string[] {
    return [];
  }

  public subInput(key: string) {
    return null;
  }
}