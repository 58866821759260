import { FormElement } from "./FormElement";
import { DOMTemplate } from "../../denki";
import { node } from "../Util";
import { FieldInputElement } from "./FieldInputElement";
import { OptionType } from "../../digitalForm";

export class FormRadioElement extends FormElement implements FieldInputElement {
  validationErrors: string[];
  public readonly fieldsElement: HTMLElement;
  private readonly map: { [key: string]: HTMLInputElement } = {};

  constructor(id: string, options: OptionType[], template: DOMTemplate) {
    super(id, template.get("field-element"));
    this.fieldsElement = this.element.querySelector("[data-role=elements]");

    const fieldset = node('div', { "class": "radio_wrapper" });
    this.virtualInput.setAttribute("data-id", id);
    this.virtualInput.setAttribute("name", id);
    const name = id;
    for (let i = 0, l = options.length; i < l; i++) {
      const option = options[i];
      const [key, label] = (() => {
        if (typeof option === "string") {
          return [option, option];
        } else {
          return [option.value, option.label];
        }
      })();
      const optionName = `${name}-${key}`;
      const dataId = optionName;
      const elementId = `form_element-${dataId}`;
      const input = node('input', {
        type: 'radio',
        name: id,
        'data-name': optionName,
        'data-id': dataId,
        id: elementId,
        value: key
      });
      input.addEventListener("change", () => {
        this.value = key;
      });
      this.map[key] = input;
      // if (data.default && data.default === key) {
      //   input.checked = true;
      // }
      const wrap = node('div', { "class": "input_wrapper" });
      wrap.appendChild(input);
      wrap.appendChild(node('label', { for: elementId }, label));
      fieldset.appendChild(wrap);

      this.fieldsElement.appendChild(fieldset);
    }
    this.virtualInput.addEventListener("change", () => {
      const options = Array.from(this.fieldsElement.querySelectorAll<HTMLInputElement>(`[name="${id}"]`));
      options.forEach(option => option.checked = this.value === option.value);
    });
  }

  public get mapKeys(): string[] {
    return Object.keys(this.map);
  }

  public subInput(key: string) {
    return this.map[key];
  }

  public focus() {
  }
}