import { FormElement } from "./FormElement";
import { DOMTemplate } from "../../denki";
import { node } from "../Util";
import { FieldInputElement } from "./FieldInputElement";
import { TemplateManager, PhoneNumber, PhoneNumberValidator } from "../../digitalForm";
import * as validator from "../../validator";

export class FormPhoneNumberElement extends FormElement implements FieldInputElement {
  public readonly fieldsElement: HTMLElement;
  public readonly firstInput: HTMLInputElement;
  public readonly middleInput: HTMLInputElement;
  public readonly lastInput: HTMLInputElement;

  constructor(name: string, template: DOMTemplate) {
    super(name, template.get("field-element"));
    this.fieldsElement = this.element.querySelector("[data-role=elements]");
    const div = document.createElement("div");
    div.setAttribute("data-template", "input_phone_number");
    this.fieldsElement.appendChild(div);
    const id = name;
    this.virtualInput.setAttribute("data-id", id);
    this.virtualInput.setAttribute("name", id);
    this.firstInput = node('input', {
      type: 'text',
      name: "first",
      placeholder: "例：000",
      minlength: "2",
      maxlength: "4",
      "data-id": `${id}-first`,
      id: `form_element-${id}-first`
    });

    this.middleInput = node("input", {
      type: "text",
      name: "middle",
      placeholder: "例：0000",
      minlength: "2",
      maxlength: "4",
      "data-id": `${id}-middle`,
      id: `form_element-${id}-middle`
    });

    this.lastInput = node("input", {
      type: "text",
      name: "last",
      placeholder: "例：0000",
      minlength: "4",
      maxlength: "4",
      "data-id": `${id}-last`,
      id: `form_element-${id}-last`
    });

    div.appendChild(this.firstInput);
    div.appendChild(node("label", {}, "-"));
    div.appendChild(this.middleInput);
    div.appendChild(node("label", {}, "-"));
    div.appendChild(this.lastInput);

    this.virtualInput.addEventListener("change", () => {
      this.decodeValue();
    });

    [this.firstInput, this.middleInput, this.lastInput].forEach((input) => {
      let editing = false;
      input.addEventListener("compositionstart", e => {
        editing = true;
      });
      input.addEventListener("compositionend", e => {
        editing = false;
        this.encodeValue();
      });
      ["paste", "cut", "change", "keyup"].forEach(event => {
        input.addEventListener(event, e => {
          if (!editing) {
            this.encodeValue();
          }
        });
      });
    });
  }

  private encodeValue() {
    const first = TemplateManager.Util.convertToASCIIDigit(this.firstInput.value);
    const middle = TemplateManager.Util.convertToASCIIDigit(this.middleInput.value);
    const last = TemplateManager.Util.convertToASCIIDigit(this.lastInput.value);
    if (!first) {
      if (!middle && !last) {
        this.value = "";
        return;
      }
      this.value = `${middle}-${last}`
      return;
    }
    this.value = `(${first})${middle}-${last}`;
  }

  private decodeValue() {
    const value = this.value;
    if (value) {
      const parsed = TemplateManager.Util.parsePhoneNumber(value);
      this.firstInput.value = parsed.first.trim();
      this.middleInput.value = parsed.middle.trim();
      this.lastInput.value = parsed.last.trim();
    } else {
      this.firstInput.value = "";
      this.middleInput.value = "";
      this.lastInput.value = "";
    }
    this.updateValidation();
  }

  resetValidationError() {
    this.firstInput.classList.remove("error");
    this.middleInput.classList.remove("error");
    this.lastInput.classList.remove("error");
  }

  get phoneNumber(): PhoneNumber{
    return {
      first: this.firstInput.value,
      middle: this.middleInput.value,
      last: this.lastInput.value
    }
  }

  get validationErrors(): string[] {
    this.resetValidationError();

    let results = [];
    const errors = validator.validate(this.phoneNumber, PhoneNumberValidator);
    const properties = errors.properties;
    if (properties) {
      if (properties.first) {
        this.firstInput.classList.add("error");
        results.push(...properties.first);
      }
      if (properties.middle) {
        this.middleInput.classList.add("error");
        results.push(...properties.middle);
      }
      if (properties.last) {
        this.lastInput.classList.add("error");
        results.push(...properties.last);
      }
    }
    if (errors.all) {
      this.firstInput.classList.add("error");
      this.middleInput.classList.add("error");
      this.lastInput.classList.add("error");
      results.push(...errors.all);
    }
    return results;
  }

  public focus() {
    this.firstInput.focus();
  }

  public get mapKeys(): string[] {
    return ["first", "middle", "last"];    
  }

  public subInput(name: string) {
    if (name === "first") return this.firstInput;
    if (name === "middle") return this.middleInput;
    if (name === "last") return this.lastInput;
    return null;
  }
}
